import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useEffect, useMemo, useReducer, } from "react";
import appSlice, { initialState, setLoggedinUser } from "./appSlice";
import useAppActions from "./useAppActions";
import { RelativeComponentLoader } from "@/Components/Loader";
import { getUser } from "@/services/auth";
export const AppContext = createContext({
    state: initialState,
    dispatch: () => null,
});
const AppProvider = ({ children }) => {
    const [state, dispatch] = useReducer(appSlice.reducer, appSlice.getInitialState());
    const { fetchTenantInfo, loadingTenantInfo } = useAppActions(dispatch);
    useEffect(() => {
        const userFromLocalStorage = getUser();
        if (!userFromLocalStorage) {
            return;
        }
        // if app context does not have loggedin user, set it from local storage
        if (!state.loggedinUser) {
            dispatch(setLoggedinUser(userFromLocalStorage));
            return;
        }
        if (state.loggedinUser) {
            fetchTenantInfo();
        }
    }, [state.loggedinUser]);
    const values = useMemo(() => ({
        state,
        dispatch,
    }), [state, dispatch]);
    return (_jsx(AppContext.Provider, { value: values, children: loadingTenantInfo ? (_jsx(RelativeComponentLoader, { label: "Loading..." })) : (children) }));
};
export default AppProvider;
