import { APIClient } from "./api_helper";
import { tenantInfoSchema } from "./types";
export const api = new APIClient();
// Summary v2
export const getCostBreakdown = (path, params) => api.get(`/summary/v2/cost/${path}`, params);
export const getSavingsBreakdown = (path, params) => api.get(`/summary/v2/savings/${path}`, params);
export const getSummaryPageOpportunities = (params) => api.get("/summary/v2/opportunities", params);
export const getOpportunities = (data) => api.get("/opportunities/", data);
export const updateOpportunities = (data) => api.post("/opportunities/", data);
export const getOpportunitiesSummary = (data) => api.get("/opportunities/summary", data);
export const getOpportunitiesResourceNameFilters = (data) => api.get("/opportunities/resource_name_filter", data);
export const getOpportunityNameFilters = () => api.get("/opportunities/filters/opportunity_name", {});
export const getOpportunitiesResource = (data) => api.get("/opportunities/resource", data);
export const getOpportunitiesRedirection = (data) => api.get("/opportunities/redirection", data);
export const getOpportunityUpdates = (data) => api.get("/opportunities/updates", data);
export const getQuerySqlLineageById = (id, params) => api.get(`/query/v2/sql_lineage/${id}`, params, 60000);
export const getQueryGroupSqlLineageById = (id, params) => api.get(`/query/v2/sql_lineage_group/${id}`, params, 60000);
export const getQueryGroupDetailsByHash = (hash, params) => api.get(`/query/v2/query_group/stats/${hash}`, params);
export const getQueryTextByHash = (hash) => api.get(`/query/v2/query_group/query_text/${hash}`, {});
export const getTenantInfo = () => api.get(`/auth/tenant-info`, {}, 10000, tenantInfoSchema);
export const getSSOUserInfo = () => api.get("/auth/supertokens_user_info", {});
export const postRegisterSSO = (data) => api.post("/auth/register-sso", data);
export const updateTenantInfo = (data) => api.update(`/auth/tenant-info`, data, tenantInfoSchema);
export const postSendDatamateRequest = (requestType) => api.post("/notification/send_datamate_request", {
    request_type: requestType,
});
