import React, { memo, useMemo } from "react";
import { MultiPhaseDropdown, MultiSelect } from "../../Components/Form";
import styles from "./styles.module.scss";
import { useCallback, useEffect, useState } from "react";
import { FilterTagWrapper } from "../../Components/Tags";
import { FlexListSummary } from "../../Components/ListSummary";
import { CollapsibleField } from "../../Components/Text";
import { DateRange } from "../../Components/DateRange";
import { ExplorerProvider, useExplorer } from "./ExplorerContext";
import {
  APIFilterDropdown,
  StaticFilterDropdown,
} from "../../Components/FilterDropdown";
import { useNavigate, useSearchParams } from "react-router-dom";
import { addSearchParams, removeSearchParams } from "../../helpers/utils";
import { capitalize, uniqueId } from "lodash";
import { Button } from "reactstrap";
import { createPortal } from "react-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  EFFORT_OPTIONS,
  GET_OPPORTUNITIES_DATA,
  GET_OPPORTUNITY_NAME_FILTERS,
  GET_SUMMARY_DATA,
  PERIOD_OPTIONS,
  RESOURCE_TYPE_OPTIONS,
  ResourceTagColors,
  SORT_OPTIONS,
  STATUS_OPTIONS,
} from "./constants";
import {
  getOpportunities,
  getOpportunityNameFilters,
  getOpportunitiesRedirection,
  getOpportunitiesResourceNameFilters,
  getOpportunitiesSummary,
} from "../../helpers/apis";
import { RelativeComponentLoader } from "../../Components/Loader";
import { useUsers } from "../../helpers/hooks";
import dayjs from "dayjs";
import DataGrid from "../../Components/DataGrid/Datagrid";
import { Select, Tooltip } from "@uicore";
import {
  ArrowDown,
  ClockBlackIcon,
  InfoGray,
  SortAscending,
  SortDefault,
  SortDescending,
} from "@assets/icons";
import {
  getNamedResourceImageByType,
  getResourceImageByType,
} from "./components/ResourceTag/utils";
import ResourceTag from "./components/ResourceTag/ResourceTag";
import { getUser } from "@/services/auth";
import {
  getEffortLabel,
  getMoneySavings,
  getOpportunityStatus,
  getResourceImageType,
  getResourceTypeURL,
} from "./utils";
import { useOpportunities } from "./useOpportunity";
import StatusAssignerDropdown from "./components/StatusAssignerDropdown/StatusAssignerDropdown";
import OwnerAssignerDropdown from "./components/OwnerAssignerDropdown/OwnerAssignerDropdown";
import EffortTag from "./components/EffortTag/EffortTag";
import StatusTag from "./components/StatusTag/StatusTag";
import {
  getDateByEndOfDayWithoutTimeoffset,
  getDateByStartOfDayWithoutTimeoffset,
} from "@/Components/DateRange/utils";
import { useAppState } from "@/modules/app/useAppContext";
import { getResourceOpportunityUrl } from "../Query/components";

const PreSelectFilters = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const PRE_SELECT_FILTER_OPTIONS = [
    {
      label: "Assigned to me",
      values: [{ owner: getUser()?.id }],
    },
    {
      label: "Implemented Opportunities",
      values: [{ status: "implemented" }],
    },
    {
      label: "Read Dormant Tables",
      values: [{ opportunity_name: "read_dormant_90d" }],
    },
    {
      label: "Copy queries on big warehouse",
      values: [{ opportunity_name: "copy_on_big_warehouse" }],
    },
    {
      label: "Zero impact queries",
      values: [{ opportunity_name: "zero_impact" }],
    },
    {
      label: "Queries with remote spillage",
      values: [{ opportunity_name: "query_with_remote_spillage" }],
    },
  ];

  const handlePreSelect = (values) => {
    searchParams.forEach((value, key) => {
      searchParams.delete(key);
    });
    values.forEach((value) => {
      searchParams.append(Object.keys(value)[0], Object.values(value)[0]);
    });
    navigate(`?${searchParams.toString()}`);
  };

  const isSelected = (values) => {
    const valuesMap = new Map();
    values.forEach((value) => {
      const [key, val] = Object.entries(value)[0];
      if (!valuesMap.has(key)) {
        valuesMap.set(key, []);
      }
      valuesMap.get(key).push(String(val));
    });

    for (const [key, valArray] of valuesMap.entries()) {
      const searchParamsValues = searchParams.getAll(key);
      if (searchParamsValues.length !== valArray.length) {
        return false;
      }
      if (!searchParamsValues.every((val, idx) => valArray.includes(val))) {
        return false;
      }
    }
    const totalUniqueKeysInSearchParams = new Set(
      Array.from(searchParams.keys())
    ).size;
    if (totalUniqueKeysInSearchParams !== valuesMap.size) {
      return false;
    }

    return true;
  };

  return (
    <div className="d-flex gap-sm flex-wrap">
      {PRE_SELECT_FILTER_OPTIONS.map((option, i) => (
        <Button
          key={i}
          color={isSelected(option.values) ? "primary" : "outline-primary"}
          onClick={() => handlePreSelect(option.values)}
        >
          {option.label}
        </Button>
      ))}
    </div>
  );
};

const FilterSelectorDropdown = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { state } = useExplorer();

  const FILTER_OPTIONS = [
    {
      value: "effort",
      label: "Effort",
      subComponent: (close) => {
        return (
          <StaticFilterDropdown
            id="effort-filter"
            label={"Effort"}
            value={searchParams.getAll("effort") || []}
            options={EFFORT_OPTIONS}
            onChange={(value) => {
              setSearchParams(addSearchParams("effort", value));
              close();
            }}
            showSearch={false}
          />
        );
      },
    },
    {
      value: "resource_type",
      label: "Resource Type",
      subComponent: (close) => {
        return (
          <StaticFilterDropdown
            id="resource-type-filter"
            label={"Resource Type"}
            value={searchParams.getAll("resource_type") || []}
            options={RESOURCE_TYPE_OPTIONS}
            onChange={(value) => {
              setSearchParams(addSearchParams("resource_type", value));
              close();
            }}
            showSearch={false}
          />
        );
      },
    },
    {
      value: "owner",
      label: "Owner",
      subComponent: (close) => {
        return (
          <StaticFilterDropdown
            id="owner-filter"
            label={"Owner"}
            value={searchParams.getAll("owner") || []}
            options={state?.userList}
            onChange={(value) => {
              setSearchParams(addSearchParams("owner", value));
              close();
            }}
          />
        );
      },
    },
    {
      value: "opportunity_name",
      label: "Opportunity Name",
      subComponent: (close) => {
        return (
          <StaticFilterDropdown
            id="opportunity-name-filter"
            label={"Opportunity Name"}
            value={searchParams.getAll("opportunity_name") || []}
            options={state?.opportunityNames || []}
            onChange={(value) => {
              setSearchParams(addSearchParams("opportunity_name", value));
              close();
            }}
          />
        );
      },
    },
    {
      value: "resource_name",
      label: "Resource Name",
      subComponent: (close) => {
        return (
          <APIFilterDropdown
            id="resource-name-filter"
            label={"Resource Name"}
            value={searchParams.getAll("resource_name") || []}
            apiFunction={getOpportunitiesResourceNameFilters}
            onChange={(value) => {
              setSearchParams(addSearchParams("resource_name", value));
              close();
            }}
          />
        );
      },
    },
    {
      value: "status_name",
      label: "Status",
      subComponent: (close) => {
        return (
          <StaticFilterDropdown
            id="status-filter"
            label={"Status"}
            value={searchParams.getAll("status") || []}
            options={STATUS_OPTIONS}
            onChange={(value) => {
              setSearchParams(addSearchParams("status", value));
              close();
            }}
            showSearch={false}
          />
        );
      },
    },
  ];

  return (
    <MultiPhaseDropdown
      id="multi-phase-dropdown"
      options={FILTER_OPTIONS}
      onChange={(value) => console.log("Selected:", value)}
      placement={"left"}
      showDivider
    >
      <div className={styles.dropdown}>
        <div className={styles.label}>
          Filter By: <span className={styles.selected}></span>
        </div>
        <div className={styles.arrow_down}>
          <ArrowDown />
        </div>
      </div>
    </MultiPhaseDropdown>
  );
};

const SortIcon = ({ currentSort = false, sortDirection = "" }) => {
  return (
    <div className="cursor-pointer">
      {(!currentSort || sortDirection === "") && <SortDefault />}
      {currentSort === true && sortDirection === "asc" && <SortAscending />}
      {currentSort === true && sortDirection === "desc" && <SortDescending />}
    </div>
  );
};

const SelectorDropdown = ({
  id = "sort-selector",
  label = "Sort By",
  options,
  method,
  setMethod,
}) => {
  return (
    <Select
      id={id}
      onChange={setMethod}
      value={method}
      options={options}
      label={label}
      showDivider
    />
  );
};

const Details = ({
  opportunity_id,
  resource_name,
  resource_type,
  resource_uri,
  opportunity_name,
  description,
  last_seen,
  first_seen,
  count,
  total_count,
}) => {
  const firstSeenTooltipId = uniqueId("first-seen-tooltip");
  const lastSeenTooltipId = uniqueId("last-seen-tooltip");
  return (
    <div className={styles.details}>
      <div className={styles.row1}>
        <div className={styles.resource_image}>
          {getNamedResourceImageByType(getResourceImageType(resource_type))}
        </div>
        <div className={styles.resource_name}>{resource_name}</div>
        <div className={styles.opportunity_name}>{opportunity_name}</div>
      </div>
      {(resource_type === 1 || resource_type === 2) && (
        <div className={styles.row1_5}>
          {`${resource_type === 1 ? "ID" : "Path"}:`}
          <div className={styles.uri}>{resource_uri}</div>
        </div>
      )}
      <div className={styles.row2}>
        <div className={styles.description}>
          <CollapsibleField
            text={description}
            defaultLines={2}
            alignCenter={true}
            textStyle=""
          />
        </div>
      </div>
      <div className={styles.row3}>
        <div className={styles.time}>
          <div className={styles.image}>
            <ClockBlackIcon />
          </div>
          <div className={styles.seen}>
            <Tooltip
              content={
                <>
                  <div>First seen</div>
                  <div>
                    {dayjs(first_seen).format("DD MMM YYYY hh:mm:ss A")}
                  </div>
                </>
              }
              placement="top"
            >
              {dayjs(first_seen).fromNow()} ago
            </Tooltip>{" "}
            |{" "}
            <Tooltip
              content={
                <>
                  <div>Last seen</div>
                  <div>{dayjs(last_seen).format("DD MMM YYYY hh:mm:ss A")}</div>
                </>
              }
              placement="top"
            >
              {dayjs(last_seen).fromNow()} old
            </Tooltip>
          </div>
        </div>
        {resource_type === 1 && (
          <div className={styles.count}>
            <Tooltip
              content={`This opportunity was detected ${count} times in ${total_count} query runs that happened. `}
              placement="top"
            >
              Count: <span>{count}</span>
            </Tooltip>
          </div>
        )}
      </div>
    </div>
  );
};

const ResourceHeader = () => {
  return (
    <div className={styles.effortHeader}>
      <div className={styles.headerTitle}>Resource</div>
      <Tooltip
        content={
          "This column shows all the resources associated with the opportunity including the main resource specified in details column."
        }
        placement="top"
      >
        <InfoGray />
      </Tooltip>
    </div>
  );
};

const Resource = ({ resources }) => {
  // only 3 resources are shown
  const [shownResources, setShownResources] = useState(resources.slice(0, 3));
  const [expanded, setExpanded] = useState(false);
  // we will show ... below if there are more than 3 resources

  return (
    <div className={styles.resources}>
      {shownResources.map((resource, i) => (
        <ResourceTag resource={resource} index={i} key={i} />
      ))}
      {resources.length > 3 && (
        <div
          id="collapsible-resource"
          className={styles.more}
          onClick={(e) => {
            e.stopPropagation();
            setExpanded(!expanded);
            setShownResources(expanded ? resources.slice(0, 3) : resources);
          }}
        >
          ...
        </div>
      )}
    </div>
  );
};

const MoneySavingHeader = ({ sort, sortDirection, onSort }) => {
  return (
    <>
      <div className={styles.moneySavingHeader} onClick={onSort}>
        <div className={styles.headerTitle}>Money Savings</div>
        <Tooltip
          content={
            "Possible money savings amount during the period specified in the top right corner of the page"
          }
          placement="top"
        >
          <InfoGray />
        </Tooltip>
        <SortIcon
          sortDirection={sortDirection}
          currentSort={sort === "cost_savings_max"}
        />
      </div>
    </>
  );
};

const MoneySaving = ({ savings }) => {
  const { currency } = useAppState();

  return (
    <div className={styles.moneySaving}>
      {getMoneySavings(savings?.min, savings?.max, currency)}
    </div>
  );
};

const TimeSavingHeader = ({ sort, sortDirection, onSort }) => {
  return (
    <div className={styles.timeSavingHeader} onClick={onSort}>
      <div className={styles.headerTitle}>Time Savings</div>
      <Tooltip
        content={
          "Possible time savings during the period specified in the top right corner of the page"
        }
        placement="top"
      >
        <InfoGray />
      </Tooltip>
      <SortIcon
        sortDirection={sortDirection}
        currentSort={sort === "time_savings_max"}
      />
    </div>
  );
};

const TimeSaving = ({ time }) => {
  return <div className={styles.timeSaving}>{time}</div>;
};

const EffortHeader = () => {
  return (
    <div className={styles.effortHeader}>
      <div className={styles.headerTitle}>Effort</div>
      <Tooltip
        content={
          <div className="d-flex flex-column gap-xs">
            Effort is
            <div>Zero: no action necessary outside of the product UI </div>
            <div>
              Low: can be done in in 0-4 hours Medium: can be done in 4-8 hours
            </div>
            <div>High: requires more than 8 hours</div>
          </div>
        }
        placement="top"
      >
        <InfoGray />
      </Tooltip>
    </div>
  );
};

const OwnerSelectorDropdown = ({
  options,
  owner,
  setOwner,
  opportunity_id,
  startDate,
  endDate,
}) => {
  const user = getUser();
  const { mutation } = useOpportunities(GET_OPPORTUNITIES_DATA);
  const { mutate } = mutation;

  const optionsWithUnassigned = useMemo(() => {
    return [
      {
        label: "Un-assigned",
        value: "-1",
      },
      ...options,
    ];
  }, [options]);

  const handleOwnerChange = (e, opportunity_id) => {
    mutate({
      changes: [
        {
          opportunity_id,
          owner: e,
        },
      ],
      user_id: user.id,
      start_date: startDate,
      end_date: endDate,
    });
  };

  const ownerSelectorId = uniqueId(`owner-selector`);
  return (
    <div onClick={(e) => e.preventDefault()}>
      <Select
        id={ownerSelectorId}
        onChange={(e) => {
          handleOwnerChange(e, opportunity_id);
          setOwner(e);
        }}
        value={owner}
        options={optionsWithUnassigned}
        className={styles.owner_dropdown}
        placeholder="Un-assigned"
        showDivider
      />
    </div>
  );
};

const StatusHeader = () => {
  return (
    <div className={styles.effortHeader}>
      <div className={styles.headerTitle}>Status</div>
      <Tooltip
        content={
          <div className="d-flex flex-column gap-xs">
            <div>
              Active: This opportunity has been discovered but not implemented
              or dismissed yet.
            </div>
            <div>
              Implemented: This opportunity has been implemented already
            </div>
            <div>
              Dismissed: This opportunity cannot be implemented and it has been
              dismissed.
            </div>
          </div>
        }
        placement="top"
      >
        <InfoGray />
      </Tooltip>
    </div>
  );
};

const FilterTags = ({ userList }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const filters = useMemo(() => {
    return Array.from(searchParams.keys()).reduce((acc, key) => {
      if (
        key === "start_date" ||
        key === "end_date" ||
        key === "period" ||
        key === "sort" ||
        key === "page" ||
        key === "size"
      ) {
        return acc;
      }

      if (key === "owner") {
        const values = searchParams.getAll(key);
        acc[`${capitalize(key)}`] = {
          filterStr: values.map((value) => {
            const user = userList.find((user) => user.value === Number(value));
            return user?.label || value;
          }),
          label: capitalize(key),
          onclose: (value) => {
            const userId = userList.find((user) => user.label === value)?.value;
            setSearchParams(removeSearchParams(key, userId));
          },
        };
        return acc;
      }

      const values = searchParams.getAll(key);
      acc[`${capitalize(key)}Filter`] = {
        filterStr: values,
        label: capitalize(key),
        onclose: (value) => {
          setSearchParams(removeSearchParams(key, value));
        },
      };
      return acc;
    }, {});
  }, [searchParams, setSearchParams, userList]);

  return (
    <div className={styles.filterTags}>
      <FilterTagWrapper filters={filters} />
    </div>
  );
};

const SummaryData = ({ getFiltersData, firstUpdate }) => {
  const { state } = useExplorer();
  const [summaryData, setSummaryData] = useState(null);
  const { currency } = useAppState();

  const { isLoading: isSummaryDataLoading } = useQuery({
    queryKey: [
      GET_SUMMARY_DATA,
      state?.startDate,
      state?.endDate,
      state?.effortFilter,
      state?.resourceNameFilter,
      state?.opportunityName,
      state?.resourceTypeFilter,
      state?.ownerFilter,
      state?.statusFilter,
      state?.period,
    ],
    queryFn: () => getOpportunitiesSummary(getFiltersData()),
    onSuccess: (data) => {
      data.forEach((item) => {
        switch (item.label) {
          case "Money Savings":
            item.value = getMoneySavings(
              item.value.min,
              item.value.max,
              currency
            );
        }
        return item;
      });
      setSummaryData(data);
    },
    enabled: firstUpdate,
  });

  const summaryDataWithInfo = useMemo(() => {
    if (!summaryData) {
      return [];
    }
    return summaryData.map((item) => {
      switch (item.label) {
        case "Money Savings":
          item.info =
            "Possible money savings amount during the period specified in the top right corner of the page";
          break;
        case "Time Savings":
          item.info =
            "Possible time savings during the period specified in the top right corner of the page";
          break;
        default:
          break;
      }
      return item;
    });
  }, [summaryData]);

  return (
    <>
      {isSummaryDataLoading ? (
        <RelativeComponentLoader componentHeight={15} />
      ) : (
        <div className={styles.flexListSummary}>
          <FlexListSummary summary={summaryDataWithInfo || []} />
        </div>
      )}
    </>
  );
};

const CustomDataGrid = memo(() => {
  const {
    state,
    gridApiRef,
    setOwnerList,
    setStartDate,
    setEndDate,
    handleSortMethodChange,
    handleGridSortChange,
    handleOwnerChange,
    onSelectionChanged,
  } = useExplorer();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { mutate } = useMutation(getOpportunitiesRedirection, {
    onSuccess: (data, variables) => {
      if (!data.url) {
        return;
      }
      navigate(
        getResourceOpportunityUrl({
          ...variables,
          url: data.url,
          startDate: state?.startDate,
          endDate: state?.endDate,
        })
      );
    },
  });

  if (!state?.data) {
    return null;
  }

  const navigateToOpportunity = (opportunity) => {
    mutate(opportunity);
  };

  const handlePageChange = (page) => {
    setSearchParams(addSearchParams("page", page + 1));
  };

  const handlePageSizeChange = (pageSize) => {
    setSearchParams(addSearchParams("size", pageSize));
  };

  return (
    <DataGrid
      headers={[
        {
          headerName: "Details",
          field: "details",
          minWidth: 350,
          cellRenderer: (params) => {
            return (
              <Details
                opportunity_id={params.data.opportunity_id}
                resource_name={params.data.resource_name}
                resource_type={params.data.resource_type}
                resource_uri={params.data.resource_uri}
                opportunity_name={params.data.opportunity_name}
                description={params.data.description}
                last_seen={params.data.last_seen}
                first_seen={params.data.first_seen}
                count={params.data.count}
                total_count={params.data.total_count}
              />
            );
          },
          headerCheckboxSelection: true,
          checkboxSelection: true,
        },
        {
          headerName: "Resources",
          field: "resources",
          minWidth: 130,
          cellRenderer: (params) => {
            return <Resource resources={params.data.resources} />;
          },
          headerComponent: (params) => {
            return <ResourceHeader />;
          },
        },
        {
          headerName: "Money Savings",
          field: "money_savings",
          minWidth: 130,
          cellRenderer: (params) => {
            return <MoneySaving savings={params.data.money_savings} />;
          },
          headerComponent: (params) => {
            return (
              <MoneySavingHeader
                onSort={() => handleGridSortChange("cost_savings_max")}
                sort={state?.gridSort}
                sortDirection={state?.gridSortDirection}
              />
            );
          },
        },
        {
          headerName: "Time Savings",
          field: "time_savings",
          minWidth: 120,
          cellRenderer: (params) => {
            return <TimeSaving time={params.data.time_savings} />;
          },
          headerComponent: (params) => {
            return (
              <TimeSavingHeader
                onSort={() => handleGridSortChange("time_savings_max")}
                sort={state?.gridSort}
                sortDirection={state?.gridSortDirection}
              />
            );
          },
        },
        {
          headerName: "Effort",
          field: "effort",
          minWidth: 100,
          cellRenderer: (params) => {
            return <EffortTag effort={getEffortLabel(params.data.effort)} />;
          },
          headerComponent: (params) => {
            return <EffortHeader />;
          },
        },
        {
          headerName: "Owner",
          field: "owner",
          minWidth: 140,
          cellRenderer: (params) => {
            return (
              <OwnerSelectorDropdown
                options={state?.userList || []}
                owner={
                  state?.ownerList?.find(
                    (o) => o.opportunity_id === params.data.opportunity_id
                  )?.owner
                }
                setOwner={(owner) => {
                  handleOwnerChange(owner, params?.data?.opportunity_id);
                }}
                opportunity_id={params.data.opportunity_id}
                startDate={state?.startDate}
                endDate={state?.endDate}
              />
            );
          },
        },
        {
          headerName: "Status",
          field: "status",
          minWidth: 120,
          cellRenderer: (params) => {
            return (
              <StatusTag status={getOpportunityStatus(params.data.status)} />
            );
          },
          headerComponent: (params) => {
            return <StatusHeader />;
          },
        },
      ]}
      data={state?.data}
      onSelectionChanged={onSelectionChanged}
      onRowClicked={(event) => {
        if (
          event.eventPath[0].id === "collapsible-field-more" ||
          event.eventPath[0].id === "collapsible-field-less" ||
          event.eventPath[0].id === "collapsible-resource"
        ) {
          return;
        }
        if (event.event.defaultPrevented) {
          return null;
        }
        navigateToOpportunity(event.data);
      }}
      page={state?.page}
      pageSize={state?.pageSize}
      setPage={handlePageChange}
      setPageSize={handlePageSizeChange}
      gridApiRef={gridApiRef}
      loading={state?.isDataLoading || false}
    />
  );
});

CustomDataGrid.displayName = "CustomDataGrid";

const ExplorerBase = () => {
  const {
    state,
    setOwnerList,
    setStartDate,
    setEndDate,
    setPage,
    setPageSize,
    setData,
    setDataLoading,
    setUserList,
    setFilter,
    setAssignedStatus,
    setAssignedOwner,
    setOpporunityNames,
    removeSelectedRows,
    handleSortMethodChange,
    handleFilterMethodChange,
    handleGridSortChange,
    handleOwnerChange,
    onSelectionChanged,
  } = useExplorer();

  const queryClient = useQueryClient();
  const user = getUser();
  const { mutation } = useOpportunities(GET_OPPORTUNITIES_DATA);
  const { mutate } = mutation;

  const [searchParams, setSearchParams] = useSearchParams();
  const [firstUpdate, setFirstUpdate] = useState(false);

  useEffect(() => {
    if (searchParams.get("page")) {
      setPage(parseInt(searchParams.get("page")) - 1);
    } else {
      setPage(0);
    }

    if (searchParams.get("size")) {
      setPageSize(parseInt(searchParams.get("size")));
    }

    if (searchParams.get("sort")) {
      handleSortMethodChange(searchParams.get("sort"));
    } else {
      handleSortMethodChange("last_detected");
    }

    if (searchParams.get("period")) {
      setFilter("period", searchParams.get("period"));
    } else {
      setFilter("period", "day");
    }

    if (searchParams.get("start_date") && searchParams.get("end_date")) {
      setStartDate(new Date(searchParams.get("start_date")));
      setEndDate(new Date(searchParams.get("end_date")));
    }

    if (searchParams.getAll("effort").length > 0) {
      setFilter("effort", searchParams.getAll("effort"));
    } else {
      setFilter("effort");
    }

    if (searchParams.getAll("resource_type").length > 0) {
      setFilter("resource_type", searchParams.getAll("resource_type"));
    } else {
      setFilter("resource_type");
    }

    if (searchParams.getAll("resource_name").length > 0) {
      setFilter("resource_name", searchParams.getAll("resource_name"));
    } else {
      setFilter("resource_name");
    }

    if (searchParams.getAll("opportunity_name").length > 0) {
      setFilter("opportunity_name", searchParams.getAll("opportunity_name"));
    } else {
      setFilter("opportunity_name");
    }

    if (searchParams.getAll("owner").length > 0) {
      setFilter("owner", searchParams.getAll("owner"));
    } else {
      setFilter("owner");
    }

    if (searchParams.getAll("status").length > 0) {
      setFilter("status", searchParams.getAll("status"));
    } else {
      setFilter("status");
    }
    setFirstUpdate(true);
  }, [searchParams]);

  const data = state?.data;
  const ownerList = state?.ownerList;

  const getFiltersData = () => {
    const params = {
      page: state?.page + 1,
      size: state?.pageSize,
      start_date: getDateByStartOfDayWithoutTimeoffset(
        state?.startDate
      ).toISOString(),
      end_date: getDateByEndOfDayWithoutTimeoffset(
        state?.endDate
      ).toISOString(),
      period: state?.period,
      sort: state?.gridSort,
      sort_order: state?.gridSortDirection,
    };

    if (state?.effortFilter) {
      params.effort = state?.effortFilter;
    }

    if (state?.resourceNameFilter) {
      params.resource_name = state?.resourceNameFilter;
    }

    if (state?.opportunityName) {
      params.opportunity_name = state?.opportunityName;
    }

    if (state?.resourceTypeFilter) {
      params.resource_type = state?.resourceTypeFilter;
    }

    if (state?.ownerFilter) {
      params.owner = state?.ownerFilter;
    }

    if (state?.statusFilter) {
      params.status = state?.statusFilter;
    }

    return params;
  };

  const { data: opportunitiesData, isLoading: isOpportunitiesDataLoading } =
    useQuery({
      queryKey: [
        GET_OPPORTUNITIES_DATA,
        state?.page,
        state?.pageSize,
        state?.startDate,
        state?.endDate,
        state?.sortMethod,
        state?.sortDirection,
        state?.effortFilter,
        state?.resourceNameFilter,
        state?.opportunityName,
        state?.resourceTypeFilter,
        state?.ownerFilter,
        state?.statusFilter,
        state?.period,
        state?.gridSort,
        state?.gridSortDirection,
      ],
      queryFn: () => getOpportunities(getFiltersData()),
      onSuccess: (data) => {
        setData(data);
      },
      enabled: firstUpdate,
    });

  const {
    data: opportunitiesFilter,
    isLoading: isOpportunitiesFiltersLoading,
  } = useQuery({
    queryKey: [GET_OPPORTUNITY_NAME_FILTERS],
    queryFn: () => getOpportunityNameFilters(),
    onSuccess: (data) => {
      setOpporunityNames(data);
    },
  });

  useEffect(() => {
    setDataLoading(isOpportunitiesDataLoading);
  }, [isOpportunitiesDataLoading]);

  const { users, getById } = useUsers();

  useEffect(() => {
    if (users) {
      setUserList(
        users
          .filter((user) => user.first_name || user.last_name)
          .map((user) => ({
            label: `${user.first_name} ${user.last_name}`,
            value: user.id,
          }))
      );
    }
  }, [users]);

  if (!data) {
    return null;
  }

  if (!ownerList) {
    setOwnerList(
      data.items.map((item) => ({
        opportunity_id: item.opportunity_id,
        owner: item.owner,
      }))
    );
  }

  const handleBulkApply = () => {
    const changes = state?.selectedRows.map((row) => ({
      opportunity_id: row.opportunity_id,
      owner: state?.assignedOwner,
      status: state?.assignedStatus,
    }));

    mutate({
      changes,
      user_id: user.id,
    });
  };

  return (
    <div className={styles.explorer}>
      <div className={styles.title}>Discover</div>
      <div className={styles.filters}>
        <div className={styles.preSelect}>
          <PreSelectFilters />
        </div>
        <div className={styles.dropdowns}>
          {/* <SelectorDropdown
            options={SORT_OPTIONS}
            method={state?.sortMethod}
            setMethod={(value) => {
              setSearchParams(addSearchParams("sort", value));
            }}
          /> */}
          <FilterSelectorDropdown />
          {/* <SelectorDropdown
            id="period-selector"
            label="Period"
            options={PERIOD_OPTIONS}
            method={state?.period}
            setMethod={(value) => {
              setSearchParams(addSearchParams("period", value));
            }}
          /> */}
          <DateRange
            startDate={state?.startDate}
            endDate={state?.endDate}
            availableStaticRanges={["Last day", "Last 7 days", "Last 28 days"]}
            disableCalendarSelection
            onDateRangeSelect={(s, e) => {
              setSearchParams((prev) => {
                const params = new URLSearchParams(prev);
                params.set("start_date", s?.toISOString());
                params.set("end_date", e?.toISOString());
                return params.toString();
              });
            }}
          />
        </div>
      </div>
      <FilterTags userList={state?.userList} />
      <SummaryData getFiltersData={getFiltersData} firstUpdate={firstUpdate} />

      <div className={styles.opportunities}>
        <div className={styles.heading}>
          <div className={styles.title}>Opportunities</div>
          {/* <SortSelectorDropdown
            options={SORT_OPTIONS}
            sortMethod={state?.sortMethod}
            setSortMethod={handleSortMethodChange}
          /> */}
        </div>

        {state?.selectedRows.length > 0 && (
          <div className={styles.rowsSelected}>
            <div className={styles.count}>
              {`${state?.selectedRows.length} item${
                state?.selectedRows.length > 1 ? "s are" : " is"
              } selected`}
            </div>
            <div className="d-flex gap-sm align-items-center">
              <StatusAssignerDropdown
                setAssignedStatus={setAssignedStatus}
                assignedStatus={state?.assignedStatus}
                disableUpdateOnChange={true}
                startDate={state?.startDate}
                endDate={state?.endDate}
              />
              <OwnerAssignerDropdown
                setAssignedOwner={setAssignedOwner}
                assignedOwner={state?.assignedOwner}
                disableUpdateOnChange={true}
                startDate={state?.startDate}
                endDate={state?.endDate}
              />
              <Button
                className={styles.apply}
                color="primary"
                onClick={handleBulkApply}
              >
                Apply
              </Button>
              <Button
                className={styles.cancel}
                color="primary"
                onClick={removeSelectedRows}
              >
                Cancel
              </Button>
            </div>
          </div>
        )}

        <div className={styles.datagrid}>
          <CustomDataGrid />
        </div>
      </div>
    </div>
  );
};

const Explorer = () => {
  return (
    <ExplorerProvider>
      <ExplorerBase />
    </ExplorerProvider>
  );
};

export { Explorer };
