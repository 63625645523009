import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useQuery } from "react-query";
import { Stack, Tabs, Tile, Card, NumberFormatter, Select } from "@uicore";
import { useState, useEffect } from "react";
import classes from "../summary.module.scss";
import AggregateFilter from "./AggregateFilter";
import BreakdownSkeleton from "./BreakdownSkeleton";
import { getSavingsBreakdown } from "@/helpers/apis";
import AssistedSavingsSection from "./AssistedSavingsSection";
import { AggregateByValues } from "./constants";
import SavingsInFuture from "./SavingsInFuture";
import AutonomousSavingsSection from "./AutonomousSavingsSection";
import { formatDate } from "./utils";
import AccordionTitle from "./AccordionTitle";
import FailureComponent from "./FailureComponent";
import { useAppState } from "@/modules/app/useAppContext";
import { getDateByStartOfDayWithoutTimeoffset, getDateByEndOfDayWithoutTimeoffset, } from "@/Components/DateRange/utils";
import SummaryChatButton from "./SummaryChatButton";
import dayjs from "dayjs";
import styles from "./styles.module.scss";
const SavingsBreakdown = ({ isFuture, currentStartDate, currentEndDate, }) => {
    const { currency } = useAppState();
    const [savingsType, setSavingsType] = useState("autonomous");
    const [aggregateBy, setAggregateBy] = useState(isFuture ? AggregateByValues.Monthly : AggregateByValues.Daily);
    const [savingsPeriod, setSavingsPeriod] = useState("next1Year");
    const yearMode = aggregateBy === AggregateByValues.Monthly;
    const onAggregateSelect = (value) => {
        setAggregateBy(value);
    };
    const getEndPoint = () => {
        if (isFuture) {
            return `${savingsType}/estimate`;
        }
        return savingsType;
    };
    const { isLoading, isFetching, data, error, refetch } = useQuery({
        queryKey: [
            `savingsBreakdown-${isFuture ? "future" : "past"}`,
            currentStartDate,
            currentEndDate,
            aggregateBy,
        ],
        queryFn: () => {
            var _a;
            return getSavingsBreakdown(getEndPoint(), {
                start_date: getDateByStartOfDayWithoutTimeoffset(currentStartDate).toISOString(),
                end_date: (_a = getDateByEndOfDayWithoutTimeoffset(currentEndDate)) === null || _a === void 0 ? void 0 : _a.toISOString(),
                aggregation_level: aggregateBy,
            });
        },
        enabled: Boolean(currentStartDate && currentEndDate),
        onError(err) {
            // @ts-expect-error window prop
            posthog.capture("savingsBreakdownFailed", { error: err });
        },
    });
    const { data: overallSavingsData, refetch: refetchOverallSavings, isLoading: isSavingsLoading, } = useQuery({
        queryKey: [
            `overallSavingsBreakdown-${isFuture ? "future" : "past"}`,
            currentStartDate,
            currentEndDate,
            aggregateBy,
            savingsPeriod,
        ],
        queryFn: () => {
            var _a;
            return getSavingsBreakdown(isFuture ? `overall/estimate` : "overall", {
                start_date: getDateByStartOfDayWithoutTimeoffset(currentStartDate).toISOString(),
                end_date: (_a = getDateByEndOfDayWithoutTimeoffset(currentEndDate)) === null || _a === void 0 ? void 0 : _a.toISOString(),
                aggregation_level: aggregateBy,
                savings_period: savingsPeriod,
            });
        },
        enabled: Boolean(currentStartDate && currentEndDate),
        onError(err) {
            // @ts-expect-error window prop
            posthog.capture("savingsBreakdownFailed", { error: err });
        },
    });
    useEffect(() => {
        refetch();
        refetchOverallSavings();
    }, [
        savingsType,
        refetch,
        aggregateBy,
        currentEndDate,
        currentStartDate,
        refetchOverallSavings,
        isFuture,
    ]);
    if (isLoading || isSavingsLoading) {
        return _jsx(BreakdownSkeleton, { type: "section" });
    }
    return (_jsxs(Card, { className: classes.section_card, children: [_jsx(AccordionTitle, { title: _jsxs(Stack, { className: "w-100", children: [_jsx("h4", { className: "text-black", children: "Savings" }), _jsx("div", { className: "spacer" }), isFuture && (_jsx(Select, { id: "summary-savings-period-dropdown", onChange: (e) => {
                                setSavingsPeriod(e);
                            }, className: styles.period_selection, value: savingsPeriod, options: [
                                { value: "thisYear", label: "This Year" },
                                { value: "next1Year", label: "Next 1 Year" },
                            ] }))] }), howToText: "How to read the charts", howToContent: _jsx("p", { children: isFuture
                        ? "These section show the Snowflake savings data for the period specified for Future State. Saving may be coming from autonomous agent actions or your team implementing the recommendations shown as opportunities. We estimate future savings for a month based on the analysis of different parameters, including savings realized in the past (if any). Then, we predict future savings for the selected period by simply multiplying future savings for a month by the no. of months in future in the selected period."
                        : "These charts show the Snowflake savings data for the period specified for Current state. Saving may be coming from autonomous agent actions or your team implementing the recommendations shown as opportunities. Charts can aggregate data at daily, weekly or monthly level. Don't miss the DataPilot icon in the top right corner of the chart to start talking with the data!" }) }), _jsx(Stack, { children: error ? (_jsx(FailureComponent, { title: "Failed to load savings breakdown. Please try again later." })) : (_jsxs(_Fragment, { children: [_jsxs("div", { className: classes.tileWrap, children: [_jsx(Tile, { color: "#01CD8C", title: "Total Money Savings", helpText: isFuture
                                        ? `Projected money savings by the product from ${dayjs().format("DD MMM YYYY")} till 31 Dec ${dayjs().format("YYYY")}` // TODO: Update this text
                                        : `Total money saved by the product during the period selected: ${formatDate(currentStartDate, yearMode)} to ${formatDate(currentEndDate, yearMode)}`, value: _jsx(NumberFormatter, { value: isFuture
                                            ? overallSavingsData === null || overallSavingsData === void 0 ? void 0 : overallSavingsData.total_savings_estimate
                                            : overallSavingsData === null || overallSavingsData === void 0 ? void 0 : overallSavingsData.total_savings, options: { currency } }), children: _jsxs(Stack, { direction: "column", children: [_jsx(Tile, { title: "Autonomous Money Savings", helpText: isFuture
                                                    ? `Projected money savings by the product from ${dayjs().format("DD MMM YYYY")} till 31 Dec ${dayjs().format("YYYY")}`
                                                    : "Money saved automatically by the product during the period, without users doing anything", value: _jsx(NumberFormatter, { value: isFuture
                                                        ? overallSavingsData === null || overallSavingsData === void 0 ? void 0 : overallSavingsData.total_autonomous_savings_estimate
                                                        : overallSavingsData === null || overallSavingsData === void 0 ? void 0 : overallSavingsData.total_autonomous_savings, options: { currency } }) }), _jsx(Tile, { title: "Assisted Money Savings", helpText: isFuture
                                                    ? `Projected money saved from ${dayjs().format("DD MMM YYYY")} till 31 Dec ${dayjs().format("YYYY")} if users implement the opportunities identified`
                                                    : "Money saved during the period when users implemented the opportunities identified by the product.", value: _jsx(NumberFormatter, { value: isFuture
                                                        ? overallSavingsData === null || overallSavingsData === void 0 ? void 0 : overallSavingsData.total_assisted_savings_estimate
                                                        : overallSavingsData === null || overallSavingsData === void 0 ? void 0 : overallSavingsData.total_assisted_savings, options: { currency } }) })] }) }), _jsx(Tile, { title: "Total Time Savings", helpText: isFuture
                                        ? `Projected time saved from ${dayjs().format("DD MMM YYYY")} till 31 Dec ${dayjs().format("YYYY")} if users implement the opportunities identified`
                                        : `Time saved during the period when users implemented the opportunities identified by the product.`, value: isFuture
                                        ? overallSavingsData === null || overallSavingsData === void 0 ? void 0 : overallSavingsData.total_time_saved_estimate
                                        : overallSavingsData === null || overallSavingsData === void 0 ? void 0 : overallSavingsData.time_saved, className: classes.time_saved })] }), _jsx(Stack, { direction: "column", className: classes.graphs, children: isFuture ? (_jsx(SavingsInFuture, { data: overallSavingsData })) : (_jsxs(_Fragment, { children: [_jsxs(Stack, { className: "justify-content-between", children: [" ", _jsx(Tabs, { items: [
                                                    { label: "Autonomous", value: "autonomous" },
                                                    { label: "Assisted", value: "assisted" },
                                                ], selectedTab: savingsType, onTabSelect: setSavingsType }), _jsxs(Stack, { className: "align-items-center", children: [savingsType !== "assisted" && (_jsx(AggregateFilter, { onSelect: onAggregateSelect, aggregateBy: aggregateBy, isFuture: isFuture, startDate: currentStartDate, endDate: currentEndDate })), _jsx(SummaryChatButton, { chartType: isFuture ? "future" : "current", aggregation: aggregateBy, analysisType: "savings", startDate: currentStartDate, endDate: currentEndDate, savingsType: savingsType })] })] }), _jsxs("div", { className: "flex-1", children: [savingsType === "autonomous" ? (_jsx("div", { style: { height: 400, padding: "1rem" }, children: _jsx(AutonomousSavingsSection, { yearMode: yearMode, currency: currency, data: data, isFetching: isFetching, savingsType: savingsType, aggregateBy: aggregateBy, isFuture: isFuture }) })) : null, savingsType === "assisted" ? (_jsx(AssistedSavingsSection, Object.assign({}, data))) : null] })] })) })] })) })] }));
};
export default SavingsBreakdown;
