export const AggregateByValues = {
    Daily: "day",
    Weekly: "week",
    Monthly: "month",
};
export const BarColors = {
    compute: "#247EFE",
    storage: "#FAC515",
    serverless: "#7A5AF8",
    total_estimate: "#8390A3",
    compute_estimate: "#8390A3",
    storage_estimate: "#8390A3",
    total_storage_estimate: "#8390A3",
    serverless_estimate: "#8390A3",
    stage: "#EE46BC",
    failsafe: "#EF8161",
    hybrid_table_storage: "#51E5E6",
    total_storage: "#8454FF",
    total: "#7A5AF8",
    search_optimization: "#51E5E6",
    automatic_clustering: "#8454FF",
    materialized_views: "#EF8161",
    snowpipe: "#EE46BC",
    data_transfer: "#FDD835",
    query_acceleration: "#01CD8C",
    value: "#01CD8C",
    number_of_decisions: "#9382D9",
    "compute cost": "#5B9FFF",
    "query acceleration cost": "#FFCE73",
};
export const TotalCostEntities = {
    overall: {
        fields: [
            {
                key: { current: "total", estimate: "total_estimate" },
                tabKey: "overall",
                label: "total",
                hideSticker: true,
                isTotal: true,
            },
            {
                key: { current: "compute", estimate: "compute_estimate" },
                label: "compute",
                showChangePercentage: true,
            },
            {
                key: { current: "storage", estimate: "storage_estimate" },
                label: "storage",
                showChangePercentage: true,
            },
            {
                key: { current: "serverless", estimate: "serverless_estimate" },
                label: "serverless",
                showChangePercentage: true,
            },
        ],
    },
    compute: {
        fields: [
            {
                key: { current: "compute", estimate: "compute_estimate" },
                label: "compute",
                showChangePercentage: true,
            },
            {
                key: { current: "compute", estimate: "compute_estimate" },
                label: "compute",
                isTotal: true,
                showChangePercentage: true,
            },
        ],
    },
    storage: {
        fields: [
            {
                key: { current: "storage", estimate: "storage_estimate" },
                label: "storage",
                showChangePercentage: true,
            },
            {
                key: { current: "stage", estimate: "stage_estimate" },
                label: "stage",
                showChangePercentage: true,
            },
            {
                key: { current: "failsafe", estimate: "failsafe_estimate" },
                label: "failsafe",
                showChangePercentage: true,
            },
            {
                key: {
                    current: "hybrid_table_storage",
                    estimate: "hybrid_table_storage_estimate",
                },
                label: "hybrid_table_storage",
                showChangePercentage: true,
            },
            {
                key: { current: "total_storage", estimate: "total_storage_estimate" },
                label: "total_storage",
                isTotal: true,
                showChangePercentage: true,
            },
        ],
    },
    serverless: {
        fields: [
            {
                key: { current: "total", estimate: "total_estimate" },
                label: "serverless",
                isTotal: true,
                hideSticker: true,
            },
            {
                key: {
                    current: "search_optimization",
                    estimate: "search_optimization_estimate",
                },
                label: "search_optimization",
                showChangePercentage: true,
            },
            {
                key: {
                    current: "automatic_clustering",
                    estimate: "automatic_clustering_estimate",
                },
                label: "automatic_clustering",
                showChangePercentage: true,
            },
            {
                key: {
                    current: "materialized_views",
                    estimate: "materialized_views_estimate",
                },
                label: "materialized_views",
                showChangePercentage: true,
            },
            {
                key: { current: "snowpipe", estimate: "snowpipe_estimate" },
                label: "snowpipe",
                showChangePercentage: true,
            },
            {
                key: { current: "data_transfer", estimate: "data_transfer_estimate" },
                label: "data_transfer",
                showChangePercentage: true,
            },
            {
                key: {
                    current: "query_acceleration",
                    estimate: "query_acceleration_estimate",
                },
                label: "query_acceleration",
                showChangePercentage: true,
            },
        ],
    },
    autonomous: {
        fields: [
            {
                key: {
                    current: "value",
                    estimate: "value_estimate",
                },
                label: "Autonomous savings",
            },
            {
                key: {
                    current: "number_of_decisions",
                    estimate: "number_of_decisions_estimate",
                },
                label: "AI Decisions",
                hideCurrency: true,
            },
        ],
    },
    warehouse: {
        fields: [
            {
                key: { current: "total cost", estimate: "total_estimate" },
                label: "total",
                hideSticker: true,
                isTotal: true,
                showChangePercentage: true,
            },
            {
                key: { current: "compute cost", estimate: "" },
                label: "compute cost",
                showChangePercentage: true,
            },
            {
                key: { current: "query acceleration cost", estimate: "" },
                label: "query acceleration cost",
                showChangePercentage: true,
            },
        ],
    },
};
