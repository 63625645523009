import styles from "./styles.module.scss";
import { useState } from "react";
import { WarehouseList } from "./WarehouseList";
import SidebarModal from "../../Components/SidebarModal";
import { WarehouseDetails } from "./WarehouseDetails";
import { Paginate } from "../../Components/Paginate";
import { useQuery } from "react-query";
import {
  getAllWarehouses,
  getWarehouseFilters,
  getWarehouseLastEventTime,
} from "../../helpers/backend_helper";
import { ComponentLoader } from "../../Components/Loader";
import { FilterTagWrapper } from "../../Components/Tags";
import {
  GET_LAST_UPDATED_TIME,
  GET_WAREHOUSE,
  GET_WAREHOUSE_FILTERS,
} from "./constants";
import { Dropdown } from "../../Components/Form";
import { ReactComponent as ArrowDown } from "../../assets/icons/arrow_down.svg";
import { ReactComponent as CalendarIcon } from "../../assets/icons/calendar.svg";
import dayjs from "dayjs";
import TimeInfoDisclaimer from "../../Components/TimeInfoDisclaimer";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { addSearchParams, removeSearchParams } from "../../helpers/utils";
import { useAppState } from "@/modules/app/useAppContext";
import { getCurrencySymbol } from "@/uiCore";

const RenderTextBasedOnPeriod = ({ period }) => {
  const endDate = dayjs().subtract(1, "day");
  let startDate;

  switch (period) {
    case 1:
      startDate = endDate;
      return (
        <div className={styles.date_range}>
          {startDate.format("D MMM YYYY")}
        </div>
      );

    case 7:
      startDate = endDate.subtract(7, "days");
      return (
        <div className={styles.date_range}>
          {startDate.format("D MMM YYYY")} - {endDate.format("D MMM YYYY")}
        </div>
      );

    case 30:
      startDate = endDate.subtract(1, "month");
      return (
        <div className={styles.date_range}>
          {startDate.format("D MMM YYYY")} - {endDate.format("D MMM YYYY")}
        </div>
      );

    default:
      return null;
  }
};

const WarehouseHeader = ({ period, setPeriod }) => {
  const ALLOWED_PERIODS = [
    {
      label: "Last Day",
      period: 1,
    },
    {
      label: "Last Week",
      period: 7,
    },
    {
      label: "Last Month",
      period: 30,
    },
  ];

  const getLabelByPeriod = (period) => {
    return ALLOWED_PERIODS.find((p) => p.period === period).label;
  };

  const { data: infoTimeString, isLoading } = useQuery({
    queryKey: [GET_LAST_UPDATED_TIME],
    queryFn: getWarehouseLastEventTime,
  });

  return (
    <div className="d-flex justify-content-between align-items-center gap-lg bg-white p-3 border-radius-top">
      <div className="d-flex gap-lg">
        <div className="fs-4">Warehouses</div>
      </div>
      <div className="d-flex align-items-center gap-sm">
        {!isLoading && infoTimeString && (
          <TimeInfoDisclaimer
            numOfSecondsActive={3}
            textToDisplay={
              <div className="text-muted m-2">
                The Data was last updated at{" "}
                <span className="text-black fw-semibold">
                  {infoTimeString["time_string"]}
                </span>{" "}
                UTC
              </div>
            }
          />
        )}
        <RenderTextBasedOnPeriod period={period} />
        <Dropdown
          id="period-dropdown"
          onChange={setPeriod}
          value={period}
          options={ALLOWED_PERIODS.map((p) => ({
            label: <div>{p.label}</div>,
            value: p.period,
          }))}
          showDivider
        >
          <div className={styles.period_dropdown}>
            <CalendarIcon />
            <div className={styles.period_label}>
              {getLabelByPeriod(period)}
            </div>
            <div className={styles.arrow_down}>
              <ArrowDown />
            </div>
          </div>
        </Dropdown>
      </div>
    </div>
  );
};

const WarehouseListWithFilter = ({
  setSelectedWarehouseRk,
  period,
  defaultFilters = {},
}) => {
  const { currency, currencySymbol } = useAppState();
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(0);
  const [tagFilter, setTagFilter] = useState(defaultFilters.tags);
  const [warehouseCostFilter, setCostFilter] = useState(defaultFilters.cost);
  const [warehouseFilter, setWarehouseFilter] = useState(
    defaultFilters.warehouse
  );
  const [autoTuneFilter, setAutoTuneFilter] = useState(defaultFilters.autotune);
  const [sizeFilter, setSizeFilter] = useState(defaultFilters.size);
  const [sortAttribute, setSortAttribute] = useState("");
  const [sortOrder, setSortOrder] = useState({
    total_cost: "",
    execution_time_p90: "",
    median_total_elapsed_time: "",
    median_queuing_time: "",
    total_active_time: "",
    idle_time: "",
  });

  const handleFilterChange = (setFilter, param) => {
    return (value) => {
      setSearchParams(addSearchParams(param, value));
      setFilter(value);
    };
  };

  const handleTagFilterChange = handleFilterChange(setTagFilter, "tags");
  const handleWarehouseFilterChange = handleFilterChange(
    setWarehouseFilter,
    "warehouse"
  );
  const handleSizeFilterChange = handleFilterChange(setSizeFilter, "size");
  const handleCostFilterChange = handleFilterChange(setCostFilter, "cost");
  const handleAutotuneFilterChange = handleFilterChange(
    setAutoTuneFilter,
    "autotune"
  );

  const getOptions = (options) =>
    options
      ? options.map((o) =>
          o === "" ? { label: "None", value: o } : { label: o, value: o }
        )
      : [];

  const autoTuneFilterOptions = [
    { label: "On", value: "auto-tune-enabled" },
    { label: "Off", value: "auto-tune-disabled" },
  ];

  const handleCostSortChange = (so) => {
    if (so) {
      setSortAttribute("total_cost");
      setSortOrder((prevSortOrder) => ({
        ...prevSortOrder,
        total_cost: so,
      }));
    }
  };

  const handlePercentileExecTimeSortChange = (so) => {
    if (so) {
      setSortAttribute("execution_time_p90");
      setSortOrder((prevSortOrder) => ({
        ...prevSortOrder,
        execution_time_p90: so,
      }));
    }
  };

  const handleMedianElapsedSortChange = (so) => {
    if (so) {
      setSortAttribute("median_total_elapsed_time");
      setSortOrder((prevSortOrder) => ({
        ...prevSortOrder,
        median_total_elapsed_time: so,
      }));
    }
  };

  const handleActiveTimeSortChange = (so) => {
    if (so) {
      setSortAttribute("total_active_time");
      setSortOrder((prevSortOrder) => ({
        ...prevSortOrder,
        total_active_time: so,
      }));
    }
  };

  const handleIdleTimeSortChange = (so) => {
    if (so) {
      setSortAttribute("idle_time");
      setSortOrder((prevSortOrder) => ({
        ...prevSortOrder,
        idle_time: so,
      }));
    }
  };

  const handleMedianQueuingTimeSortChange = (so) => {
    if (so) {
      setSortAttribute("median_queuing_time");
      setSortOrder((prevSortOrder) => ({
        ...prevSortOrder,
        median_queuing_time: so,
      }));
    }
  };

  const { data: filters, isLoading: isfiltersLoading } = useQuery({
    queryKey: [GET_WAREHOUSE_FILTERS],
    queryFn: () => getWarehouseFilters(),
  });

  const getFilterParams = () => {
    const params = { page: page + 1, size: 8 };
    if (period) params.period = period;
    if (sortAttribute) {
      params.sortAttribute = sortAttribute;
      params.sortOrder = sortOrder[sortAttribute];
    }
    if (warehouseFilter.length > 0) params.warehouseName = warehouseFilter;
    if (tagFilter.length > 0) params.tags = tagFilter;
    if (warehouseCostFilter) params.costFilter = warehouseCostFilter;
    if (sizeFilter.length > 0) params.warehouseSize = sizeFilter;
    if (autoTuneFilter.length > 0) params.autoTuneFilter = autoTuneFilter;
    return params;
  };

  const { data: warehouses, isLoading: isQueriesLoading } = useQuery({
    queryKey: [
      GET_WAREHOUSE,
      page,
      ...warehouseFilter,
      ...tagFilter,
      ...sizeFilter,
      ...autoTuneFilter,
      sortOrder,
      sortAttribute,
      warehouseCostFilter,
      period,
    ],
    queryFn: () => getAllWarehouses(getFilterParams()),
  });

  const handleRowClick = (rk) => {
    setSelectedWarehouseRk(rk);
  };

  const handleFilterRemoval = (filter, setFilter, param) => {
    return (removedFilter) => {
      const newFilter = filter.filter((f) => f !== removedFilter);
      setSearchParams(removeSearchParams(param, removedFilter));
      setFilter(newFilter);
    };
  };

  if (isQueriesLoading || isfiltersLoading) return <ComponentLoader />;

  return (
    <div className="p-3 pt-0 d-flex flex-column bg-white border-radius-bottom">
      <div className="d-flex gap-md align-items-center">
        <FilterTagWrapper
          filters={{
            tagFilter: {
              filterStr: tagFilter,
              label: "Tags",
              onclose: handleFilterRemoval(tagFilter, setTagFilter, "tags"),
            },
            warehouseFilter: {
              filterStr: warehouseFilter,
              label: "Warehouse",
              onclose: handleFilterRemoval(
                warehouseFilter,
                setWarehouseFilter,
                "warehouse"
              ),
            },
            warehouseCostFilter: {
              filterStr: warehouseCostFilter,
              label: currencySymbol,
              onclose: () => {
                setCostFilter(undefined);
                setSearchParams(
                  removeSearchParams("cost", warehouseCostFilter)
                );
              },
            },
            sizeFilter: {
              filterStr: sizeFilter,
              label: "Size",
              onclose: handleFilterRemoval(sizeFilter, setSizeFilter, "size"),
            },
          }}
        />
      </div>
      <WarehouseList
        header={[
          {
            id: 1,
            label: "Warehouse name",
            filter: {
              filterType: "dropdown",
              value: warehouseFilter,
              options: getOptions(filters.warehouse_names),
              onChange: handleWarehouseFilterChange,
            },
          },
          {
            id: 2,
            label: "Cost",
            filter: {
              filterType: "text",
              value: warehouseCostFilter,
              placeHolder: `Specify cost in ${getCurrencySymbol(currency)}`,
              onChange: handleCostFilterChange,
              label: "Cost",
            },
            sort: {
              onChange: handleCostSortChange,
              value: sortOrder.total_cost,
            },
          },
          {
            id: 3,
            label: "Size",
            filter: {
              filterType: "dropdown",
              value: sizeFilter,
              options: getOptions(filters.warehouse_sizes),
              onChange: handleSizeFilterChange,
            },
          },
          {
            id: 4,
            label: "90% exec. time",
            sort: {
              onChange: handlePercentileExecTimeSortChange,
              value: sortOrder.execution_time_p90,
            },
          },
          {
            id: 5,
            label: "Insights",
            filter: {
              filterType: "dropdown",
              value: tagFilter,
              options: getOptions(filters.tags),
              onChange: handleTagFilterChange,
            },
          },
          { id: 6, label: "Auto suspend" },
          {
            id: 7,
            label: "Med. elapsed time",
            sort: {
              onChange: handleMedianElapsedSortChange,
              value: sortOrder.median_total_elapsed_time,
            },
          },
          {
            id: 8,
            label: "Auto Tune Savings",
            filter: {
              filterType: "dropdown",
              value: autoTuneFilter,
              options: autoTuneFilterOptions,
              onChange: handleAutotuneFilterChange,
              showSearch: false,
            },
          },
          {
            id: 9,
            label: "Med. queuing time",
            sort: {
              onChange: handleMedianQueuingTimeSortChange,
              value: sortOrder.median_queuing_time,
            },
          },
          {
            id: 10,
            label: "Active time",
            sort: {
              onChange: handleActiveTimeSortChange,
              value: sortOrder.total_active_time,
            },
          },
          {
            id: 11,
            label: "Idle time",
            sort: {
              onChange: handleIdleTimeSortChange,
              value: sortOrder.idle_time,
            },
          },
        ]}
        onRowClick={handleRowClick}
        warehouses={warehouses}
        resetPage={setPage}
      />
      <Paginate
        itemCount={warehouses.total}
        page={page}
        pageSize={warehouses.size}
        numPages={warehouses.pages}
        onPageClick={setPage}
      />
    </div>
  );
};

const Warehouses = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const warehouseFilters = {
    tags: searchParams.getAll("tags") || [],
    cost: searchParams.get("cost") || undefined,
    warehouse: searchParams.getAll("warehouse") || [],
    size: searchParams.getAll("size") || undefined,
    autotune: searchParams.getAll("autotune") || [],
  };
  const navigate = useNavigate();
  const [period, setPeriod] = useState(30); //period can be 1, 7 or 30

  return (
    <div className="d-flex flex-column">
      <WarehouseHeader period={period} setPeriod={setPeriod} />
      <WarehouseListWithFilter
        setSelectedWarehouseRk={(rk) => navigate(`/warehouse/${rk}`)}
        period={period}
        defaultFilters={warehouseFilters}
      />
    </div>
  );
};

export { Warehouses };
