import { createSlice } from "@reduxjs/toolkit";
import { USD, USDSymbol } from "./constants";
import { getCurrencySymbol } from "@/uiCore";
export const initialState = {
    currency: USD,
    currencySymbol: USDSymbol,
    teammatesEnabled: false,
    loggedinUser: null,
};
const appSlice = createSlice({
    name: "appState",
    initialState,
    reducers: {
        setLoggedinUser: (state, action) => {
            state.loggedinUser = action.payload;
        },
        setCurrency: (state, action) => {
            state.currency = action.payload;
            state.currencySymbol = getCurrencySymbol(action.payload);
        },
        setTeammatesEnabled: (state, action) => {
            state.teammatesEnabled = action.payload;
        },
        setCurrencySymbol: (state, action) => {
            state.currencySymbol = action.payload;
        },
    },
});
export const { setCurrency, setCurrencySymbol, setTeammatesEnabled, setLoggedinUser, } = appSlice.actions;
export default appSlice;
