import { jsx as _jsx } from "react/jsx-runtime";
import { Shines2Icon } from "@/assets/icons";
import { Drawer } from "@uicore";
import classes from "../summary.module.scss";
import { RelativeComponentLoader } from "@/Components/Loader";
import { lazy, Suspense, useEffect, useRef } from "react";
import { api } from "@/helpers/api_helper";
import { getDateByEndOfDayWithoutTimeoffset, getDateByStartOfDayWithoutTimeoffset, } from "@/Components/DateRange/utils";
import { useIntercom } from "react-use-intercom";
import { useSearchParams } from "react-router-dom";
const Chatbot = lazy(() => import("@/modules/chatbot/Chatbot"));
const SummaryChatButton = ({ chartType, analysisType, costType, savingsType, startDate, endDate, aggregation, isOpen, }) => {
    const [searchParams] = useSearchParams();
    const drawerRef = useRef(null);
    const { update } = useIntercom();
    useEffect(() => {
        var _a;
        if (isOpen) {
            (_a = drawerRef.current) === null || _a === void 0 ? void 0 : _a.open();
        }
    }, [isOpen]);
    const onRequest = (messages, sessionId) => {
        var _a;
        const userMessages = messages.filter((message) => message.role === "user");
        const lastMessage = userMessages[userMessages.length - 1];
        if (!lastMessage.content) {
            return;
        }
        return api.post("/summary/v2/datapilot/start-chart-chat", {
            session_id: sessionId,
            start_date: getDateByStartOfDayWithoutTimeoffset(startDate).toISOString(),
            end_date: (_a = getDateByEndOfDayWithoutTimeoffset(endDate)) === null || _a === void 0 ? void 0 : _a.toISOString(),
            cost_type: costType,
            savings_type: savingsType,
            chart_type: chartType,
            analysis_type: analysisType,
            aggregation,
            user_query: lastMessage.content,
        });
    };
    const instructions = `Hi! I can help you understand the ${chartType} ${analysisType} chart better. Just ask me anything about the chart`;
    // Temporary feature flag
    const showSummaryChatButton = isOpen || searchParams.get("chat") === "true";
    if (!showSummaryChatButton) {
        return;
    }
    return (_jsx(Drawer, { buttonProps: {
            outline: true,
            title: "Ask questions about this chart",
            className: classes.chatButton,
        }, icon: _jsx(Shines2Icon, {}), title: "Datapilot", ref: drawerRef, offcanvasBodyClassName: classes.chatDrawer, onOpen: () => {
            update({ hideDefaultLauncher: true });
        }, onClose: () => {
            update({ hideDefaultLauncher: false });
        }, children: _jsx("div", { className: "h-100", children: _jsx(Suspense, { fallback: _jsx(RelativeComponentLoader, { label: "Loading Chatbot" }), children: _jsx(Chatbot, { onRequest: onRequest, instructions: instructions, feedback_id: `summary-${Date.now()}`, feedback_type: `summary` }) }) }) }));
};
export default SummaryChatButton;
