import Session from "supertokens-auth-react/recipe/session";
import SuperTokens, { SuperTokensWrapper } from "supertokens-auth-react";
import ThirdParty, { Okta } from "supertokens-auth-react/recipe/thirdparty";
import { REACT_APP_API_URL, REACT_APP_HOST } from "./config";
import { SSORegister } from "./pages/Authentication/SSORegister";
import Multitenancy from "supertokens-auth-react/recipe/multitenancy";
import { getTenant } from "./helpers/tenant";

SuperTokens.init({
  //   enableDebugLogs: true,
  appInfo: {
    appName: "altimate",
    apiDomain: REACT_APP_API_URL,
    websiteDomain: window.location.origin,
    apiBasePath: "/superauth",
    websiteBasePath: "/login",
  },
  style: `
        [data-supertokens~=superTokensBranding] {
            display: none;
        }
    `,
  usesDynamicLoginMethods: true,
  recipeList: [
    Multitenancy.init({
      override: {
        functions: (oI) => {
          return {
            ...oI,
            getTenantId: getTenant,
          };
        },
      },
    }),
    ThirdParty.init(),
    Session.init({
      sessionTokenFrontendDomain: `.${REACT_APP_HOST}`,
      tokenTransferMethod: "header", // or "cookie"
    }),
  ],
});
