import styles from "./styles.module.scss";
import { useEffect, useState } from "react";
import { Card, CardBody } from "reactstrap";
import { RelativeComponentLoader } from "../../Components/Loader";
import { ListingTable } from "../../Components/Tables";
import { Paginate } from "../../Components/Paginate";
import { ColorInsightTags, FilterTagWrapper } from "../../Components/Tags";
import TimeInfoDisclaimer from "../../Components/TimeInfoDisclaimer";
import classNames from "classnames";
import { useQuery } from "react-query";
import {
  getDatasetList,
  getDatasetListFilters,
} from "../../helpers/backend_helper";
import { getIconByDatastoreType } from "./DatastoreImage";
import { useLocation, useNavigate } from "react-router-dom";
import { CopyIconButton } from "../../Components/CopyButton";
import { Tooltip } from "react-tooltip";
import { NoDatasets } from "./NoDatasets";
import { useAppState } from "@/modules/app/useAppContext";
import { formatNumber } from "@/uiCore";

const TableHeader = ({
  numTables,
  tableFilters,
  resetColumnFilter,
  filterObj,
}) => {
  const { currencySymbol } = useAppState();
  return (
    <div className="d-flex gap-md align-items-center">
      <div className="fs-4 fw-500 me-3">Tables</div>
      <FilterTagWrapper
        filters={{
          tableRKs: {
            label: "Tables",
            filterStr: Object.keys(filterObj),
            onclose: (removedFilter) => {
              resetColumnFilter.table(
                tableFilters.table_rk.filter(
                  (filter) => filter !== filterObj[removedFilter]
                )
              );
            },
          },
          tagRKs: {
            label: "Tags",
            filterStr: tableFilters.tag_rks,
            onclose: (removedFilter) =>
              resetColumnFilter.tags(
                tableFilters.tag_rks.filter(
                  (filter) => filter !== removedFilter
                )
              ),
          },
          totalCost: {
            label: `${currencySymbol} Total`,
            filterStr: tableFilters.total_cost,
            onclose: () => resetColumnFilter.totalCost(null),
          },
          insertCost: {
            label: `${currencySymbol} Insertion`,
            filterStr: tableFilters.insertion_cost,
            onclose: () => resetColumnFilter.insertionCost(null),
          },
          storageCost: {
            label: `${currencySymbol} Storage`,
            filterStr: tableFilters.storage_cost,
            onclose: () => resetColumnFilter.storageCost(null),
          },
          clusteringCost: {
            label: `${currencySymbol} Clustering`,
            filterStr: tableFilters.clustering_cost,
            onclose: () => resetColumnFilter.clusteringCost(null),
          },
        }}
      />
      <div className="spacer" />
      <TimeInfoDisclaimer
        numOfSecondsActive={3}
        textToDisplay={
          <div className={classNames(styles.info_text, "text-muted m-2")}>
            Tables & costs for the past 30 days
          </div>
        }
      />
      <Card className={classNames("me-3 mb-0 no-break", styles.query_count)}>
        <CardBody className="pt-2 pb-2 ps-4 pe-4">
          <div className="d-flex align-items-center justify-content-between">
            <div>Tables: {numTables}</div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

const GET_DATASET_LIST = "GET-DATASET-LIST";
const GET_DATASET_LIST_FILTERS = "GET-DATASET-LIST-FILTERS";

const TablePath = ({ text, maxLength }) => {
  const truncatedText =
    text.length > maxLength ? text.substring(0, maxLength) + "..." : text;

  return <div className="text-muted condense-text me-2">{truncatedText}</div>;
};

const DatasetsList = () => {
  const { currency, currencySymbol } = useAppState();
  const { state } = useLocation();
  const [totalCostFilter, setTotalCostFilter] = useState(null);
  const [insertionCostFilter, setInsertionCostFilter] = useState(
    state?.filters.insertion_cost
  );
  const [storageCostFilter, setStorageCostFilter] = useState(
    state?.filters.storage_cost
  );
  const [clusteringCostFilter, setClusteringCostFilter] = useState(null);
  const [tableFilter, setTableFilter] = useState([]);
  const [sortAttribute, setSortAttribute] = useState("total_cost");
  const [sortOrder, setSortOrder] = useState({
    total_cost: "",
    insertion_cost: "",
    storage_cost: "",
    clustering_cost: "",
  });
  const size = 20;
  const [page, setPage] = useState(0);
  const [tagFilter, setTagFilter] = useState(state?.filters.tags || []);
  const navigate = useNavigate();
  const onTableClick = (item) => navigate(`/datasets/${item.resource_id}`);
  const [tableFilterDispTag, setTableFilterDispTag] = useState({});

  const getRequestBody = () => {
    const data = { page: page + 1, size: size };
    if (tableFilter) data.table_rk = tableFilter;
    if (sortAttribute) {
      data.order_by = sortAttribute;
      data.sort_order = sortOrder[sortAttribute];
    }
    if (totalCostFilter != undefined) data.total_cost = totalCostFilter;
    if (insertionCostFilter != undefined)
      data.insertion_cost = insertionCostFilter;
    if (storageCostFilter != undefined) data.storage_cost = storageCostFilter;
    if (clusteringCostFilter != undefined)
      data.clustering_cost = clusteringCostFilter;
    if (tagFilter.length > 0) data.tag_rks = tagFilter;
    return data;
  };

  const resetFilters = {
    tags: setTagFilter,
    table: setTableFilter,
    totalCost: setTotalCostFilter,
    insertionCost: setInsertionCostFilter,
    storageCost: setStorageCostFilter,
    clusteringCost: setClusteringCostFilter,
  };

  const { data: tables, isLoading: isTablesLoading } = useQuery({
    queryKey: [
      GET_DATASET_LIST,
      page,
      ...tableFilter,
      ...tagFilter,
      totalCostFilter,
      insertionCostFilter,
      clusteringCostFilter,
      storageCostFilter,
      sortOrder,
      sortAttribute,
    ],
    queryFn: () => getDatasetList(getRequestBody()),
  });

  const { data: filters, isLoading: isFiltersLoading } = useQuery({
    queryKey: [GET_DATASET_LIST_FILTERS, page],
    queryFn: () => getDatasetListFilters(),
  });

  useEffect(() => {
    if (tableFilter.length) {
      setTableFilterDispTag(
        filters.rks.reduce(
          (acc, { rk, name }) =>
            tableFilter.includes(rk) ? { ...acc, [name]: rk } : acc,
          {}
        )
      );
    }
  }, [tableFilter, filters]);

  const handleTotalCostSortChange = (so) => {
    if (so) {
      setSortAttribute("total_cost");
      setSortOrder((prevSortOrder) => ({
        ...prevSortOrder,
        total_cost: so,
      }));
    }
  };

  const handleInsertionCostSortChange = (so) => {
    if (so) {
      setSortAttribute("insertion_cost");
      setSortOrder((prevSortOrder) => ({
        ...prevSortOrder,
        insertion_cost: so,
      }));
    }
  };

  const handleStorageCostSortChange = (so) => {
    if (so) {
      setSortAttribute("storage_cost");
      setSortOrder((prevSortOrder) => ({
        ...prevSortOrder,
        storage_cost: so,
      }));
    }
  };

  const handleClusteringCostSortChange = (so) => {
    if (so) {
      setSortAttribute("clustering_cost");
      setSortOrder((prevSortOrder) => ({
        ...prevSortOrder,
        clustering_cost: so,
      }));
    }
  };

  if (isTablesLoading || isFiltersLoading) return <RelativeComponentLoader />;
  const header = [
    {
      id: 1,
      label: "Table Names",
      filter: {
        filterType: "dropdown",
        value: tableFilter,
        options: filters.rks.map((t) => ({
          label: t.name,
          value: t.rk,
        })),
        onChange: setTableFilter,
      },
    },
    {
      id: 2,
      label: "Total Cost",
      sort: {
        onChange: handleTotalCostSortChange,
        value: sortOrder.total_cost,
      },
      filter: {
        filterType: "text",
        value: totalCostFilter,
        placeHolder: `Specify cost in ${currencySymbol}`,
        onChange: setTotalCostFilter,
        label: "Total Cost",
      },
    },

    {
      id: 3,
      label: "Insertion Cost",
      sort: {
        onChange: handleInsertionCostSortChange,
        value: sortOrder.insertion_cost,
      },
      filter: {
        filterType: "text",
        value: insertionCostFilter,
        placeHolder: `Specify cost in ${currencySymbol}`,
        onChange: setInsertionCostFilter,
        label: "Insertion Cost",
      },
    },

    {
      id: 4,
      label: "Storage Cost",
      sort: {
        onChange: handleStorageCostSortChange,
        value: sortOrder.storage_cost,
      },
      filter: {
        filterType: "text",
        value: storageCostFilter,
        placeHolder: `Specify cost in ${currencySymbol}`,
        onChange: setStorageCostFilter,
        label: "Storage Cost",
      },
    },

    {
      id: 5,
      label: "Clustering Cost",
      sort: {
        onChange: handleClusteringCostSortChange,
        value: sortOrder.clustering_cost,
      },
      filter: {
        filterType: "text",
        value: clusteringCostFilter,
        placeHolder: `Specify cost in ${currencySymbol}`,
        onChange: setClusteringCostFilter,
        label: "Clustering Cost",
      },
    },

    {
      id: 6,
      label: "Insights",
      filter: {
        filterType: "dropdown",
        value: tagFilter,
        options: filters.tag_rks.map((t) => ({
          label: t,
          value: t,
        })),
        onChange: setTagFilter,
      },
    },
  ];
  if (
    tables.total === 0 &&
    !(
      totalCostFilter ||
      insertionCostFilter ||
      storageCostFilter ||
      clusteringCostFilter ||
      tableFilter ||
      tagFilter
    )
  ) {
    return <NoDatasets />; // only returned if no tables were found without applying any of the filters.
  }
  return (
    <>
      <div
        className={classNames(
          styles.dataset_list,
          "p-3 d-flex flex-column bg-white border-radius-bottom"
        )}
      >
        <TableHeader
          numTables={tables.total}
          tableFilters={getRequestBody()}
          filterObj={tableFilterDispTag}
          resetColumnFilter={resetFilters}
        />
        <ListingTable
          header={header}
          items={tables.items}
          idKey={(item) => item.rk.rk}
          onItemClick={onTableClick}
          resetPage={() => setPage(0)}
          templateColumns="6fr 1fr 1fr 1fr 1fr 4fr"
          rowRender={(item) => (
            <>
              <div className="cursor-pointer">
                <div
                  className={classNames(
                    styles.word_break,
                    "text-primary condense-text"
                  )}
                >
                  {getIconByDatastoreType("snowflake")}
                  {item.rk.name}
                </div>
                <div
                  className={styles.word_break}
                  data-tooltip-id={"table-" + item.rk.rk}
                >
                  <TablePath text={item.rk.path} maxLength={100} />
                  <CopyIconButton
                    value={item.rk.path}
                    color="rgba(8, 34, 71, 0.5)"
                    isLight
                  />
                </div>
                <Tooltip
                  id={"table-" + item.rk.rk}
                  className={styles.sync_disabled_tooltip}
                >
                  <div className="d-flex flex-column align-items-left gap-xs justify-content-center">
                    <div>{item.rk.path}</div>
                  </div>
                </Tooltip>
              </div>
              <div className={classNames(styles.word_break, "condense-text")}>
                {formatNumber(item.total_cost, { currency })}
              </div>
              <div className={classNames(styles.word_break, "condense-text")}>
                {formatNumber(item.insertion_cost, { currency })}
              </div>
              <div className={classNames(styles.word_break, "condense-text")}>
                {formatNumber(item.storage_cost, { currency })}
              </div>
              <div className={classNames(styles.word_break, "condense-text")}>
                {formatNumber(item.clustering_cost, { currency })}
              </div>
              <ColorInsightTags tags={item.tag_rks} />
            </>
          )}
        />
        <Paginate
          itemCount={tables.total}
          page={page}
          pageSize={tables.size}
          numPages={tables.pages}
          onPageClick={setPage}
        />
      </div>
      {/* <SidebarModal
          isOpen={selectedColumn !== ""}
          toggleModal={() => setSelectedColumn("")}
          width="600"
        >
          <ColumnsAccessedSidebar columnObj={selectedColumn.column} />
        </SidebarModal> */}
    </>
  );
};

export default DatasetsList;
