import { createContext, useCallback, useEffect, useRef, useState } from "react";
import ReactFlow, { Background, Controls, ReactFlowProvider } from "reactflow";
import "reactflow/dist/style.css";
import SidebarModal from "../SidebarModal";
import { TableDetails } from "./TableDetails";
import { MoreTables } from "./MoreTables";
import { useQuery } from "react-query";
import { ComponentLoader } from "../Loader";
import {
  ColumnNode,
  IconReverseEdge,
  SeeMoreNode,
  SelfConnectingEdge,
  TableNode,
} from "./CustomNodes";
import {
  COLUMNS_SIDEBAR,
  TABLES_SIDEBAR,
  createTableNode,
  destructColumn,
} from "./utils";
import LineageLegend from "./LineageLegend";
import { LensCodeModal } from "./Modals";
import {
  ApiHelper,
  Lineage,
  CllEvents,
  CLL,
} from "@components";
import styles from "./styles.module.scss";

const columnRkToTableRk = (rk) => {
  const splits = rk.split("/");
  splits.pop();
  return splits.join("/");
};

const LineageV2 = ({
  tableId,
  getStartingNode,
  postColumns,
  upstreamTables,
  downstreamTables,
  postConnectedColumns,
  height = "76vh",
}) => {
  const [isApiHelperInitialized, setIsApiHelperInitialized] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const { isLoading } = useQuery({
    queryKey: [TABLE_MAPPER, tableId],
    queryFn: () => getStartingNode({ table_id: tableId }),
    onSuccess: (t) => {
      const renderNode = {
        aiEnabled: true,
        node: {
          table: t.table,
          upstreamCount: t.upstream_count,
          downstreamCount: t.downstream_count,
          label: t.label,
          nodeType: t.resource_type,
          isExternalProject: false,
          tests: (t.tests || []).map((t) => ({ key: t.name })),
          materialization: t.materialization,
          schema: t.schema_name,
        },
      };
      ApiHelper.get = async (url, data) => {
        console.log("ApiHelper", url, data);
        switch (url) {
          case "upstreamTables": {
            setShowLoader(true);
            const { tables } = await upstreamTables(data.table);
            setShowLoader(false);
            return {
              tables: tables.map((t) => ({
                table: t.table,
                upstreamCount: t.upstream_count,
                downstreamCount: t.downstream_count,
                label: t.label,
                nodeType: t.resource_type,
                isExternalProject: false,
                tests: (t.tests || []).map((t) => ({ key: t.name })),
                materialization: t.materialization,
                schema: t.schema_name,
              })),
            };
          }
          case "downstreamTables": {
            setShowLoader(true);
            const { tables } = await downstreamTables(data.table);
            setShowLoader(false);
            return {
              tables: tables.map((t) => ({
                table: t.table,
                upstreamCount: t.upstream_count,
                downstreamCount: t.downstream_count,
                label: t.label,
                nodeType: t.resource_type,
                isExternalProject: false,
                tests: (t.tests || []).map((t) => ({ key: t.name })),
                materialization: t.materialization,
                schema: t.schema_name,
              })),
            };
          }
          case "getColumns": {
            setShowLoader(true);
            const { columns, purpose } = await postColumns({
              table_id: data.table,
            });
            setShowLoader(false);
            return {
              purpose: purpose,
              columns: columns.map((c) => ({
                name: c.name,
                table: columnRkToTableRk(c.rk),
                datatype: c.datatype,
                can_lineage_expand: c.can_lineage_expand,
                description: c.description,
              })),
            };
          }
          case "getConnectedColumns": {
            const result = await postConnectedColumns({
              column_fqns: data.targets.map((t) => `${t[0]}/${t[1]}`),
              nodes: data.currAnd1HopTables,
            });
            const column_lineage = [];
            if (!result) return { column_lineage };

            const { highlight_edges } = result;
            for (const e of highlight_edges) {
              const type = e.extra?.type || "direct";
              const lens_code = e.extra?.lens_code;
              const lens_type = e.extra?.lens_type;
              column_lineage.push({
                source: destructColumn(e["source"]),
                target: destructColumn(e["target"]),
                type: type,
                viewsType: lens_type,
                viewsCode: lens_code,
              });
            }

            return { column_lineage };
          }
          case "getLineageSettings":
            return {
              showSelectEdges: true,
              showNonSelectEdges: false,
              defaultExpansion: 1,
            };
          case "columnLineage": {
            if (data.event === "start") setShowLoader(true);
            else if (data.event === "end") setShowLoader(false);
            return;
          }
          case "init": {
            const event = new CustomEvent("renderStartNode", {
              detail: renderNode,
            });
            document.dispatchEvent(event);
            return;
          }
          case "persistLineageSettings":
          case "getExposureDetails":
          case "sendFeedback":
          case "openFile":
          case "openChat":
          case "showInfoNotification":
          case "previewFeature":
          case "telemetryEvents":
          default:
            break;
        }
      };
      setIsApiHelperInitialized(true);
    },
  });

  return (
    <div className={styles.new_lineage_container} style={{ height: height }}>
      <div className={styles.action_widget}>
        {/* <div id="expand-container" /> */}
        <div id="reset-container" />
      </div>
      {(isLoading || showLoader) && <ComponentLoader />}
      {isApiHelperInitialized && (
        <Lineage
          theme={"light"}
          dynamicLineage={{ aiEnabled: true }}
          lineageType={"dynamic"}
        />
      )}
    </div>
  );
};

const noop = () => {};

const StaticLineageContext = createContext({
  collectColumns: {},
  selectedColumn: "",
  detailColumns: undefined,
  selectedTable: "",
  setSelectedTable: () => {},
});

const LineageContext = createContext({
  showSidebar: false,
  setShowSidebar: noop,
  selectedTable: "",
  setSelectedTable: noop,
  moreTables: {},
  setMoreTables: noop,
  sidebarScreen: "",
  setSidebarScreen: noop,
  selectedColumn: {},
  setSelectedColumn: noop,
  collectColumns: {},
  setCollectColumns: noop,
  postColumns: noop,
  upstreamTables: noop,
  downstreamTables: noop,
  postConnectedColumns: noop,
  lensCodeModal: null,
  setLensCodeModal: noop,
});

const TABLE_MAPPER = "TABLE-MAPPER";

const nodeTypes = {
  table: TableNode,
  seeMore: SeeMoreNode,
  column: ColumnNode,
};
const edgeTypes = {
  selfConnecting: SelfConnectingEdge,
  iconReverse: IconReverseEdge,
};

const LineageV1 = ({
  tableId,
  getStartingNode,
  postColumns,
  upstreamTables,
  downstreamTables,
  postConnectedColumns,
  height = "60vh",
}) => {
  const flow = useRef();
  const [selectedTable, setSelectedTable] = useState("");
  const [selectedColumn, setSelectedColumn] = useState(null);
  const [collectColumns, setCollectColumns] = useState({});
  const [showSidebar, setShowSidebar] = useState(false);
  const [moreTables, setMoreTables] = useState({});
  const [sidebarScreen, setSidebarScreen] = useState("");
  const [lensCodeModal, setLensCodeModal] = useState();

  const onNodesChange = useCallback(() => {
    flow.current?.fitView({ duration: 500 });
  }, []);

  const { data, isLoading } = useQuery({
    queryKey: [TABLE_MAPPER, tableId],
    queryFn: () => getStartingNode({ table_id: tableId }),
  });

  if (isLoading) return <ComponentLoader />;
  if (!data) return <ComponentLoader />;

  return (
    <LineageContext.Provider
      value={{
        showSidebar,
        setShowSidebar,
        selectedTable,
        setSelectedTable,
        selectedColumn,
        setSelectedColumn,
        collectColumns,
        setCollectColumns,
        moreTables,
        setMoreTables,
        sidebarScreen,
        setSidebarScreen,
        postColumns,
        upstreamTables,
        downstreamTables,
        postConnectedColumns,
        lensCodeModal,
        setLensCodeModal,
      }}
    >
      <ReactFlowProvider>
        <div style={{ width: "100%", height: height, position: "relative" }}>
          <ReactFlow
            defaultNodes={[
              createTableNode(
                {
                  table: data.amundsen_rk,
                  upstreamCount: data.upstream_count,
                  downstreamCount: data.downstream_count,
                },
                0,
                ""
              ),
            ]}
            defaultEdges={[]}
            onInit={(_flow) => (flow.current = _flow)}
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            onNodesChange={onNodesChange}
            style={{ background: "#f5f5f7" }}
            proOptions={{ hideAttribution: true }}
          >
            <Background />
            <Controls />
          </ReactFlow>
          <LineageLegend />
        </div>
        <SidebarModal
          isOpen={showSidebar}
          toggleModal={() => setShowSidebar((b) => !b)}
          width="446"
        >
          {sidebarScreen === COLUMNS_SIDEBAR && <TableDetails />}
          {sidebarScreen === TABLES_SIDEBAR && <MoreTables />}
        </SidebarModal>
        <LensCodeModal />
      </ReactFlowProvider>
    </LineageContext.Provider>
  );
};

export { LineageV2 as Lineage, LineageContext, StaticLineageContext };
