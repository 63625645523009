import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Chart, Stack, Tabs, Tile, ChartTypes, Card, NumberFormatter, formatNumber, } from "@uicore";
import { useQuery } from "react-query";
import { useState, useEffect, useMemo } from "react";
import classes from "../summary.module.scss";
import { Bar } from "recharts";
import AggregateFilter from "./AggregateFilter";
import BreakdownSkeleton from "./BreakdownSkeleton";
import { TotalCostEntities, BarColors, AggregateByValues } from "./constants";
import { getCostBreakdown } from "@/helpers/apis";
import { formatDate, getKey } from "./utils";
import AccordionTitle from "./AccordionTitle";
import FailureComponent from "./FailureComponent";
import CostChartTooltip from "./CostChartTooltip";
import dayjs from "dayjs";
import { useAppState } from "@/modules/app/useAppContext";
import { getDateByEndOfDayWithoutTimeoffset, getDateByStartOfDayWithoutTimeoffset, } from "@/Components/DateRange/utils";
import SummaryChatButton from "./SummaryChatButton";
import { useSearchParams } from "react-router-dom";
import { TaskLabels } from "@components";
const CostBreakdown = ({ isFuture, currentStartDate, currentEndDate, }) => {
    const [searchParams] = useSearchParams();
    const { currency } = useAppState();
    const [costType, setCostType] = useState("overall");
    const [aggregateBy, setAggregateBy] = useState(isFuture ? AggregateByValues.Monthly : AggregateByValues.Daily);
    const yearMode = aggregateBy === AggregateByValues.Monthly;
    const onAggregateSelect = (value) => {
        setAggregateBy(value);
    };
    const getEndPoint = () => {
        if (isFuture) {
            return `${costType}/estimate`;
        }
        return costType;
    };
    const { isLoading, isFetching, data, error, refetch } = useQuery({
        queryKey: [
            `costBreakdown-${isFuture ? "future" : "past"}`,
            currentStartDate,
            currentEndDate,
            aggregateBy,
        ],
        queryFn: () => {
            var _a;
            return getCostBreakdown(getEndPoint(), {
                start_date: getDateByStartOfDayWithoutTimeoffset(currentStartDate).toISOString(),
                end_date: currentEndDate
                    ? (_a = getDateByEndOfDayWithoutTimeoffset(currentEndDate)) === null || _a === void 0 ? void 0 : _a.toISOString()
                    : undefined,
                aggregation_level: aggregateBy,
            });
        },
        enabled: Boolean(currentStartDate && currentEndDate),
        onError(err) {
            // @ts-ignore window property
            posthog.capture("costBreakdownFailed", { error: err });
        },
    });
    const { data: totalCosts, refetch: refetchTotalCosts } = useQuery({
        queryKey: [
            `totalcosts-${isFuture ? "future" : "past"}`,
            currentStartDate,
            currentEndDate,
            aggregateBy,
        ],
        queryFn: () => {
            var _a;
            return getCostBreakdown(isFuture ? `total/estimate` : "total", {
                start_date: getDateByStartOfDayWithoutTimeoffset(currentStartDate).toISOString(),
                end_date: (_a = getDateByEndOfDayWithoutTimeoffset(currentEndDate)) === null || _a === void 0 ? void 0 : _a.toISOString(),
                aggregation_level: aggregateBy,
            });
        },
        enabled: Boolean(currentStartDate && currentEndDate),
        onError(err) {
            // @ts-ignore window property
            posthog.capture("costBreakdownFailed", { error: err });
        },
    });
    useEffect(() => {
        refetchTotalCosts();
    }, [refetchTotalCosts, currentStartDate, currentEndDate]);
    useEffect(() => {
        refetch();
    }, [costType, refetch, aggregateBy, currentStartDate, currentEndDate]);
    const chartsData = useMemo(() => {
        if (!isFuture) {
            return data === null || data === void 0 ? void 0 : data.graph;
        }
        // future cost graph has only monthly aggregation
        // For current month, we need to set projected amount to be difference so the graph bar will render properly
        return data === null || data === void 0 ? void 0 : data.graph.map((item) => {
            const isCurrentMonth = dayjs().isSame(item.date, "month");
            if (!isCurrentMonth) {
                return item;
            }
            const fields = TotalCostEntities[costType].fields;
            return Object.assign(Object.assign({}, item), fields.reduce((acc, f) => {
                const keys = f.key;
                // @ts-expect-error valid keys
                acc[keys.estimate] = item[keys.estimate] - item[keys.current];
                return acc;
            }, {}));
        });
    }, [isFuture, data === null || data === void 0 ? void 0 : data.graph, costType]);
    const costBar = (props) => {
        const { d, barSize, selectedLegend } = props;
        const currentKey = getKey(d, false);
        const futureKey = getKey(d, true);
        // Since estimate legend has different name, we need to parse the string and modify to have estimate key
        const legend = (selectedLegend === null || selectedLegend === void 0 ? void 0 : selectedLegend.includes(" (projected)"))
            ? `${selectedLegend.replace(" (projected)", "")}_estimate`
            : selectedLegend;
        return (_jsxs(_Fragment, { children: [_jsx(Bar, { barSize: barSize, name: d.label, hide: Boolean(legend && legend !== currentKey), dataKey: currentKey, fill: BarColors[currentKey], stackId: "single", radius: !isFuture && props.isLast ? [7, 7, 0, 0] : [0, 0, 0, 0] }, currentKey), isFuture ? (_jsx(Bar, { barSize: barSize, name: `${d.label} (projected)`, hide: Boolean(legend && legend !== futureKey), dataKey: futureKey, fill: BarColors[futureKey], stackId: "single", radius: [3, 3, 0, 0] }, futureKey)) : null] }));
    };
    if (isLoading) {
        return _jsx(BreakdownSkeleton, { type: "section" });
    }
    return (_jsxs(Card, { className: classes.section_card, children: [_jsx(AccordionTitle, { title: _jsx("h4", { className: "text-black", children: "Costs" }), howToText: "How to read the charts", howToContent: _jsx("p", { children: isFuture
                        ? "These charts show the cost data for this year - split between already occurred costs and projected costs. If you hover over, you will see change % and breakdown no.s (if applicable). Don't miss the DataPilot icon in the top right corner of the chart to start talking with the data!"
                        : "These charts show the cost data for the period specified for Current state. Charts can aggregate data at daily, weekly or monthly level. If you hover over, you will see change % and breakdown no.s (if applicable). Don't miss the DataPilot icon in the top right corner of the chart to start talking with the data!" }) }), _jsx(Stack, { children: error ? (_jsx(FailureComponent, { title: "Failed to load cost breakdown. Please try again later." })) : (_jsxs(_Fragment, { children: [_jsx("div", { className: classes.tileWrap, children: _jsx(Tile, { color: "#FF754C", title: "Total Costs", helpText: isFuture
                                    ? "Projected total Snowflake costs during this year"
                                    : `Snowflake costs during the period selected: ${formatDate(currentStartDate, yearMode)} to ${formatDate(currentEndDate, yearMode)}`, value: _jsx(NumberFormatter, { value: totalCosts === null || totalCosts === void 0 ? void 0 : totalCosts.total, options: { currency } }), children: _jsxs(Stack, { direction: "column", children: [_jsx(Tile, { title: "Compute", value: _jsx(NumberFormatter, { value: totalCosts === null || totalCosts === void 0 ? void 0 : totalCosts.compute, options: { currency } }) }), _jsx(Tile, { title: "Storage", value: _jsx(NumberFormatter, { value: totalCosts === null || totalCosts === void 0 ? void 0 : totalCosts.storage, options: { currency } }) }), _jsx(Tile, { title: "Serverless", value: _jsx(NumberFormatter, { value: totalCosts === null || totalCosts === void 0 ? void 0 : totalCosts.serverless, options: { currency } }) })] }) }) }), _jsxs("div", { className: classes.graphs, children: [_jsxs(Stack, { className: "justify-content-between", children: [_jsx(Tabs, { items: Object.entries(TotalCostEntities.overall.fields).map(([_k, v]) => {
                                                const label = v.label;
                                                const value = v.tabKey || getKey(v, false);
                                                return {
                                                    label,
                                                    value,
                                                };
                                            }), selectedTab: costType, onTabSelect: setCostType }), _jsxs(Stack, { className: "align-items-center", children: [_jsx(AggregateFilter, { onSelect: onAggregateSelect, aggregateBy: aggregateBy, isFuture: isFuture, startDate: currentStartDate, endDate: currentEndDate }), _jsx(SummaryChatButton, { chartType: isFuture ? "future" : "current", aggregation: aggregateBy, analysisType: "cost", startDate: currentStartDate, endDate: currentEndDate, costType: costType, isOpen: !isFuture && searchParams.get("ref") === TaskLabels.ChartBot })] })] }), _jsx("div", { style: { height: 400, padding: "1rem" }, children: isFetching ? (_jsx(BreakdownSkeleton, { type: "chart" })) : (_jsx(Chart, { title: "Cost Breakdown", xAxisDataKey: "date", yAxisLabel: {
                                            value: "Cost",
                                        }, xAxisLabelFormatter: (value) => `${formatDate(value, yearMode)}`, yAxisLabelFormatter: (value) => `${formatNumber(value, {
                                            currency,
                                            maximumFractionDigits: 2,
                                        })}`, type: ChartTypes.BarChart, data: chartsData, tooltipProps: {
                                            content: (props) => (_jsx(CostChartTooltip, Object.assign({}, props, { timeRange: aggregateBy, isFuture: isFuture, type: costType }))),
                                        }, children: ({ barSize, selectedLegend }) => {
                                            const fields = isFuture
                                                ? [
                                                    TotalCostEntities[costType].fields.find((d) => d.isTotal),
                                                ]
                                                : TotalCostEntities[costType].fields.filter((d) => !d.isTotal);
                                            return (_jsx(_Fragment, { children: fields === null || fields === void 0 ? void 0 : fields.map((d, index) => {
                                                    if (!d) {
                                                        return null;
                                                    }
                                                    return (_jsx(_Fragment, { children: costBar({
                                                            d,
                                                            barSize,
                                                            selectedLegend,
                                                            isFuture: Boolean(isFuture),
                                                            data: chartsData,
                                                            isLast: index === fields.length - 1,
                                                        }) }));
                                                }) }));
                                        } })) })] })] })) })] }));
};
export default CostBreakdown;
