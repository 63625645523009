import styles from "./styles.module.scss";
import { useCallback, useEffect, useReducer, useRef, useState } from "react";
import { ScrollContainer } from "@/Components/ScrollContainer";
import { uniqueId } from "lodash";
import classNames from "classnames";

import {
  createSession,
  postFeedback,
  postTasks,
} from "../../helpers/backend_helper";
import dayjs from "dayjs";
import {
  cancelRequest,
  getAllTables,
  postChat,
  getCreditUsage,
} from "../../helpers/backend_helper";
import { ColorTag } from "../../Components/Tags";
import analysis from "@/assets/images/analysis.svg";
import data_processing from "@/assets/images/data-processing.svg";
import hand_wave from "@/assets/images/hand-wave.svg";
import tuning from "@/assets/images/tuning.svg";
import { History } from "./History";
import { CreditLimit } from "./CreditLimit";
import { isFreeStack } from "../../helpers/tenant";
import { CopilotChat } from "./CopilotChat";
import { useCopilot } from "./CopilotContext";

// Old copilot imports
import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import {
  postFeedbackRule,
  postRules,
  postTerms,
  processWorkflowInputs,
  generateDocs,
} from "../../helpers/backend_helper";

import { Workflow } from "./Workflow";
import { ComponentLoader } from "../../Components/Loader";
import { Suggestions } from "./Suggestions";
import { getChat } from "../../helpers/backend_helper";
import { WORKFLOWS, WORKFLOW_INPUT_TYPES, WORKFLOW_LABELS } from "./constants";
import { Button, FormGroup, Input, Label } from "reactstrap";
import { ACTIONS, getInitialState, processChatItem, reducer } from "./state";
import { useCopilotActions } from "./useCopilotActions";
import { postSendDatamateRequest } from "@/helpers/apis";
// Old copilot imports end

const InitialPage = () => {
  const [datamateClicked, setDatamateClicked] = useState(false);
  const { mutate } = useMutation({
    mutationFn: () => postSendDatamateRequest("datamate"),
    onSuccess: (data) => {
      if (data.ok) {
        setDatamateClicked(true);
      }
    },
  });
  return (
    <div>
      <div className="bg-white br-2 d-flex flex-column m-2 p-4 align-items-center">
        <img src={hand_wave} alt="" className={styles.initial_icon} />
        <h3 className="mb-4">Welcome to the DataPilot Platform</h3>
        {isFreeStack() && (
          <Button onClick={() => mutate()} disabled={datamateClicked}>
            Enable DataMates
          </Button>
        )}
        <div
          className={classNames("text-muted mt-4", {
            invisible: !datamateClicked,
          })}
        >
          Thanks for your request. Our team will reach out to you with the next
          steps.
        </div>
      </div>
      <div className="d-flex">
        <div className="bg-white br-2 d-flex flex-column m-2 p-4 align-items-center equal-col">
          <img src={tuning} alt="" className={styles.initial_icon_sm} />
          <div className="text-center">
            Understand data better with lineage explorer and query explanation
          </div>
        </div>
        <div className="bg-white br-2 d-flex flex-column m-2 p-4 align-items-center equal-col">
          <img src={analysis} alt="" className={styles.initial_icon_sm} />
          <div className="text-center">
            Generate dbt Models, data tests, and data documentation
          </div>
        </div>
        <div className="bg-white br-2 d-flex flex-column m-2 p-4 align-items-center equal-col">
          <img
            src={data_processing}
            alt=""
            className={styles.initial_icon_sm}
          />
          <div className="text-center">
            Find query insights, set threshold alerts, and automate warehouse
            configs
          </div>
        </div>
      </div>
    </div>
  );
};

const taskStatusColorMap = {
  DONE: "green",
  IN_PROGRESS: "orange",
  TODO: "blue",
};
const GET_ALL_TABLES_FOR_COPILOT = "GET-ALL-TABLES-FOR-COPILOT";
const GET_CREDIT_USAGE = "GET-CREDIT-USAGE";

const Copilot = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [creditLimitReached, setCreditLimitReached] = useState(false);
  const { data: tables = [], isLoading: isTablesLoading } = useQuery({
    queryKey: [GET_ALL_TABLES_FOR_COPILOT],
    queryFn: getAllTables,
  });

  const {
    workflow,
    setWorkflow,
    tasks,
    setTasks,
    taskStatus,
    setTaskStatus,
    state,
    autoApprove,
    resource,
    showSuggestions,
    premium,
    setPremium,
    setShowSuggestions,
    setResource,
    toggleAutoApprove,
    dispatch,
    getLoaderArgs,
    getErrorMessage,
  } = useCopilot();

  const {
    addNewChat,
    handleAgentError,
    resetWorkflow,
    handleChatResponse,
    _createSession,
  } = useCopilotActions();

  // const { data: creditUsage, isLoading } = useQuery({
  //   queryKey: [GET_CREDIT_USAGE],
  //   queryFn: getCreditUsage,
  //   onSuccess: (res) => {
  //     if (res?.total_credits <= res?.used_credits) {
  //       setCreditLimitReached(true);
  //     }
  //   },
  // });

  // Old copilot states
  const [ruleInput, setRuleInput] = useState("");
  const [selectedRule, setSelectedRule] = useState(null);
  const [rules, setRules] = useState(null);
  const [editorText, setEditorText] = useState("");
  const [isTermGenError, setIsTermGenError] = useState(false);
  const location = useLocation();
  const [showDatasetModal, setShowDatasetModal] = useState(false);
  const [active, setActive] = useState({});
  const [columns, setColumns] = useState([]);
  const [showEditor, setShowEditor] = useState(false);
  const [ruleFeedback, setRuleFeedback] = useState({ updated: false });
  // Old copilot states end

  // Old copilot methods

  const {
    mutate: _postTerms,
    data: terms,
    isLoading: termLoading,
  } = useMutation(postTerms, {
    onSuccess: (data) => {
      setEditorText(data.term);
      setIsTermGenError(data.term === "");
    },
  });

  const { mutate: _postFeedbackRule } = useMutation(postFeedbackRule, {
    onSuccess: () => {
      setRuleFeedback({ updated: false });
    },
  });

  const recommendations = useMemo(() => {
    if (!rules) return [];
    const _recommendations = [];
    const isFilter = Object.values(active).filter((x) => x).length > 0;
    for (const c of rules.column_rules) {
      if (isFilter && !active[c.column]) continue;
      for (const r of c.rules)
        _recommendations.push({
          id: uniqueId(),
          rule: r,
          color: "orange",
          column: c.column,
        });
    }
    for (const c of rules.cross_column_rules) {
      if (isFilter && !active["crossColumn"]) continue;
      for (const r of c.rules)
        _recommendations.push({
          id: uniqueId(),
          rule: r,
          color: "blue",
          column: "CrossColumn",
        });
    }
    const newArgs = {
      rules,
      recommendations: _recommendations,
      selectedRule,
      setRuleInput,
      setSelectedRule,
      setRuleFeedback,
    };
    dispatch({
      type: ACTIONS.OLD_COPILOT,
      payload: { items: state.items.slice(0, 1) },
    });
    addNewChat("rule-selection", false, null, newArgs);
    return _recommendations;
  }, [rules, active]);
  const isRecommend = resource && rules;
  const onTagClick = (t) => (e) => {
    e.stopPropagation();
    setActive((a) => ({ ...a, [t]: !a[t] }));
  };

  useMemo(() => {
    if (!selectedRule) return;
    const newArgs = {
      resource,
      isRecommend,
      rules,
      setSelectedRule,
      setRuleFeedback,
      ruleInput,
      _postTerms,
      setShowEditor,
      ruleFeedback,
      _postFeedbackRule,
    };
    dispatch({
      type: ACTIONS.OLD_COPILOT,
      payload: { items: state.items.slice(0, 2) },
    });
    addNewChat("rule-input", false, null, newArgs);
  }, [selectedRule]);

  useMemo(() => {
    if (!showEditor) return [];
    const newArgs = {
      termLoading,
      isTermGenError,
      terms,
      editorText,
    };
    dispatch({
      type: ACTIONS.OLD_COPILOT,
      payload: { items: state.items.slice(0, 3) },
    });
    addNewChat("term-code", false, null, newArgs);
  }, [showEditor, editorText, termLoading]);

  const oldArgs = {
    resource,
    setResource,
    isRecommend,
    rules,
    active,
    onTagClick,
    columns,
    recommendations,
    selectedRule,
    ruleInput,
    setRuleInput,
    setSelectedRule,
    setRules,
    editorText,
    setEditorText,
    isTermGenError,
    setIsTermGenError,
    location,
    showDatasetModal,
    setShowDatasetModal,
    setActive,
    setColumns,
    showEditor,
    setShowEditor,
    ruleFeedback,
    setRuleFeedback,
    _postRules: async (v) => {
      addNewChat("chat-loader", false);
      const _rules = await postRules(v);
      setRules(_rules);
      dispatch({ type: ACTIONS.DELETE_ITEM });
    },
    _postTerms,
    terms,
    termLoading,
    _postFeedbackRule,
  };

  useEffect(() => {
    const _table = location?.state?.table;
    if (!_table) return;
    addNewChat("term-table-selection", false, null, {
      ...oldArgs,
    });
    setResource(_table);
    window.history.replaceState({}, document.title);
  }, [location]);
  // Old copilot methods end

  useEffect(() => {
    const debug_copilot = location?.state?.debug_copilot;
    const query_insights_copilot = location?.state?.query_insights_copilot;
    if (debug_copilot) {
      if (tables.length === 0) return;
      if (isLoaded) return;
      setIsLoaded(true);
      setShowSuggestions(false);
      const { purpose, data_store_id } = debug_copilot;
      window.history.replaceState({}, document.title);
      if (!tables.find((t) => t.data_store_id === data_store_id)) return;
      setWorkflow("debug");
      addNewChat("table-selection", true, null, {
        tables,
        isSubmitted: true,
        purpose: purpose,
        datastoreId: data_store_id,
        onSubmit: () => {},
      });
      const _submit = async () => {
        const requestData = {
          workflow_type: "debug",
          purpose,
          workflow_inputs: { data_store_id },
        };
        addNewChat("chat-loader", false, null, { label: "Creating a plan" });
        try {
          const { tasks: _tasks, pii: _pii } = await postTasks(requestData);
          dispatch({ type: ACTIONS.DELETE_ITEM });
          if (_pii) {
            addNewChat("chat-block", true, null, { data: _pii?.message });
          } else {
            addNewChat("task-list", true, null, {
              tasks: _tasks,
              isSubmitted: true,
              onSubmit: async () => {},
            });
            _createSession(_tasks, requestData);
          }
        } catch (error) {
          handleAgentError(error);
        }
      };
      _submit();
    } else if (query_insights_copilot) {
      // if (tables.length === 0) return;
      if (isLoaded) return;
      setIsLoaded(true);
      setShowSuggestions(false);
      window.history.replaceState({}, document.title);
      setWorkflow("query_insights");
      addNewChat("code-input-tags", true, null, {
        isSubmitted: true,
        value: query_insights_copilot.value,
        tags: query_insights_copilot.tags.map((t) => t.tag_rk),
        data_store_id: query_insights_copilot.data_store_id,
        query_id: query_insights_copilot.query_id,
        start_time: query_insights_copilot.start_time,
        onSubmit: () => {},
      });
      const _submit = async () => {
        setShowSuggestions(false);
        dispatch({ type: ACTIONS.SUBMIT });
        const requestData = {
          workflow_type: "query_insights",
          purpose: query_insights_copilot.value,
          workflow_inputs: {
            value: query_insights_copilot.value,
            tags: query_insights_copilot.tags,
            data_store_id: query_insights_copilot.data_store_id,
            query_id: query_insights_copilot.query_id,
            start_time: query_insights_copilot.start_time,
          },
        };
        addNewChat("chat-loader", false, null, {
          label: "Creating a plan",
        });
        try {
          const { tasks: _tasks, pii: _pii } = await postTasks(requestData);
          dispatch({ type: ACTIONS.DELETE_ITEM });
          if (_pii) {
            addNewChat("chat-block", true, null, {
              data: _pii?.message,
            });
          } else {
            addNewChat("task-list", true, null, {
              tasks: _tasks,
              onSubmit: () => {
                dispatch({ type: ACTIONS.SUBMIT });
                _createSession(_tasks, requestData);
              },
            });
          }
        } catch (error) {
          handleAgentError(error);
        }
      };
      _submit();
    }
  }, [
    _createSession,
    addNewChat,
    handleChatResponse,
    isLoaded,
    location,
    tables,
  ]);

  useEffect(() => {
    return () => {
      resetWorkflow();
    };
  }, []);

  if (isTablesLoading) return <ComponentLoader />;

  const isFreeUser = isFreeStack();
  const isNonPremiumFeatureAvailableForFreeUser =
    isFreeUser && !creditLimitReached && !premium;
  const showSuggestionsForFreeUser =
    isFreeUser && (!creditLimitReached || premium);
  const showCreditLimitReachedForFreeUser =
    isFreeUser && !premium && creditLimitReached;

  const handleSuggestionClick = (value) => {
    if (!isFreeUser || isNonPremiumFeatureAvailableForFreeUser) {
      setShowSuggestions(false);
      dispatch({ type: ACTIONS.SET_PURPOSE, payload: { value } });
    }
  };

  const addDocGen = (workflowType, piiEnabled, _result) => {
    addNewChat("dbt-model-column-selection", true, null, {
      result: _result,
      onSubmit: async (purpose, dbt_model) => {
        dispatch({ type: ACTIONS.SUBMIT });
        const requestData = {
          workflow_type: workflowType,
          purpose,
          workflow_inputs: {
            pii_enabled: piiEnabled,
            purpose: purpose,
            dbt_model: dbt_model,
          },
        };
        addNewChat("chat-loader", false, null, {
          label: "Creating a plan",
        });
        try {
          const { tasks: _tasks, pii: _pii } = await postTasks(requestData);
          dispatch({ type: ACTIONS.DELETE_ITEM });
          if (_pii) {
            addNewChat("chat-block", true, null, {
              data: _pii?.message,
            });
          } else {
            addNewChat("task-list", true, null, {
              tasks: _tasks,
              onSubmit: () => {
                dispatch({ type: ACTIONS.SUBMIT });
                _createSession(_tasks, requestData);
              },
            });
          }
        } catch (error) {
          handleAgentError(error);
        }
        // try {
        //   // const { text: _text } = await generateDocs(purpose, dbt_model);
        //   addNewChat("dbt-description", true, null, {
        //     value: "Sample Description",
        //   });
        //   addNewChat("dbt-finish", true, null, {
        //     reset: resetWorkflow,
        //     onSubmit: () => {
        //       addDocGen(_result);
        //     },
        //   });
        // } catch (error) {
        //   handleAgentError(error);
        // }
      },
    });
  };

  return (
    <div className="h-100 d-flex gap-lg">
      <div className={classNames("d-flex flex-column gap-sm", styles.side_bar)}>
        <Workflow
          resetWorkflow={resetWorkflow}
          workflow={workflow}
          tasks={tasks}
          onItemClick={({ workflowType, inputType, premium: isPremium }) => {
            resetWorkflow();
            setWorkflow(workflowType);
            setPremium(isPremium || false);
            switch (inputType) {
              case WORKFLOW_INPUT_TYPES.DS_PURPOSE: {
                addNewChat("table-selection", true, null, {
                  tables,
                  onSubmit: async ({ datastoreId, purpose, piiEnabled }) => {
                    setShowSuggestions(false);
                    dispatch({ type: ACTIONS.SUBMIT });
                    const requestData = {
                      workflow_type: workflowType,
                      purpose: purpose,
                      workflow_inputs: {
                        data_store_id: datastoreId,
                        pii_enabled: piiEnabled,
                      },
                    };
                    addNewChat("chat-loader", false, null, {
                      label: "Creating a plan",
                    });
                    try {
                      const { tasks: _tasks, pii: _pii } = await postTasks(
                        requestData
                      );
                      dispatch({ type: ACTIONS.DELETE_ITEM });
                      if (_pii) {
                        addNewChat("chat-block", true, null, {
                          data: _pii?.message,
                        });
                      } else {
                        addNewChat("task-list", true, null, {
                          tasks: _tasks,
                          onSubmit: () => {
                            dispatch({ type: ACTIONS.SUBMIT });
                            _createSession(_tasks, requestData);
                          },
                        });
                      }
                    } catch (error) {
                      handleAgentError(error);
                    }
                  },
                });
                return;
              }
              case WORKFLOW_INPUT_TYPES.OLD_COPILOT: {
                addNewChat("term-table-selection", true, null, { ...oldArgs });
                return;
              }
              case WORKFLOW_INPUT_TYPES.SQL_QUERY: {
                addNewChat("code-input", true, null, {
                  isSubmitted: false,
                  onSubmit: async ({ purpose, piiEnabled }) => {
                    setShowSuggestions(false);
                    dispatch({ type: ACTIONS.SUBMIT });
                    const requestData = {
                      workflow_type: workflowType,
                      purpose,
                      workflow_inputs: { pii_enabled: piiEnabled },
                    };
                    addNewChat("chat-loader", false, null, {
                      label: "Creating a plan",
                    });
                    try {
                      const { tasks: _tasks, pii: _pii } = await postTasks(
                        requestData
                      );
                      dispatch({ type: ACTIONS.DELETE_ITEM });
                      if (_pii) {
                        addNewChat("chat-block", true, null, {
                          data: _pii?.message,
                        });
                      } else {
                        addNewChat("task-list", true, null, {
                          tasks: _tasks,
                          onSubmit: () => {
                            dispatch({ type: ACTIONS.SUBMIT });
                            _createSession(_tasks, requestData);
                          },
                        });
                      }
                    } catch (error) {
                      handleAgentError(error);
                    }
                  },
                });
                return;
              }
              case WORKFLOW_INPUT_TYPES.SQL_QUERY_TEXT: {
                addNewChat("code-input-text", true, null, {
                  isSubmitted: false,
                  onSubmit: async ({ purpose, piiEnabled }) => {
                    setShowSuggestions(false);
                    dispatch({ type: ACTIONS.SUBMIT });
                    const requestData = {
                      workflow_type: workflowType,
                      purpose,
                      workflow_inputs: { pii_enabled: piiEnabled },
                    };
                    addNewChat("chat-loader", false, null, {
                      label: "Creating a plan",
                    });
                    try {
                      const { tasks: _tasks, pii: _pii } = await postTasks(
                        requestData
                      );
                      dispatch({ type: ACTIONS.DELETE_ITEM });
                      if (_pii) {
                        addNewChat("chat-block", true, null, {
                          data: _pii?.message,
                        });
                      } else {
                        addNewChat("task-list", true, null, {
                          tasks: _tasks,
                          onSubmit: () => {
                            dispatch({ type: ACTIONS.SUBMIT });
                            _createSession(_tasks, requestData);
                          },
                        });
                      }
                    } catch (error) {
                      handleAgentError(error);
                    }
                  },
                });
                return;
              }
              case WORKFLOW_INPUT_TYPES.SQL_QUERY_TAGS: {
                addNewChat("code-input-tags", true, null, {
                  isSubmitted: false,
                  onSubmit: async ({ purpose, piiEnabled, tags }) => {
                    setShowSuggestions(false);
                    dispatch({ type: ACTIONS.SUBMIT });
                    const requestData = {
                      workflow_type: workflowType,
                      purpose,
                      workflow_inputs: { pii_enabled: piiEnabled, tags: tags },
                    };
                    addNewChat("chat-loader", false, null, {
                      label: "Creating a plan",
                    });
                    try {
                      const { tasks: _tasks, pii: _pii } = await postTasks(
                        requestData
                      );
                      dispatch({ type: ACTIONS.DELETE_ITEM });
                      if (_pii) {
                        addNewChat("chat-block", true, null, {
                          data: _pii?.message,
                        });
                      } else {
                        addNewChat("task-list", true, null, {
                          tasks: _tasks,
                          onSubmit: () => {
                            dispatch({ type: ACTIONS.SUBMIT });
                            _createSession(_tasks, requestData);
                          },
                        });
                      }
                    } catch (error) {
                      handleAgentError(error);
                    }
                  },
                });
                return;
              }
              case WORKFLOW_INPUT_TYPES.DOC_GEN: {
                addNewChat("manifest-file-upload", true, null, {
                  onSubmit: async ({ manifest_file, piiEnabled }) => {
                    if (manifest_file === "Error parsing JSON file") {
                      addNewChat("chat-agent-error", true, null, {
                        data: getErrorMessage(manifest_file),
                      });
                    } else {
                      setShowSuggestions(false);
                      dispatch({ type: ACTIONS.SUBMIT });
                      const requestData = {
                        workflow_type: workflowType,
                        workflow_inputs: {
                          file: manifest_file,
                          pii_enabled: piiEnabled,
                        },
                      };
                      addNewChat("parsing-file", false, null, {});
                      try {
                        const { result: _result, pii: _pii } =
                          await processWorkflowInputs(requestData);
                        dispatch({ type: ACTIONS.DELETE_ITEM });
                        if (_pii) {
                          addNewChat("chat-block", true, null, {
                            data: _pii?.message,
                          });
                        } else {
                          addNewChat("parsing-complete", false, null, {});
                          addDocGen(workflowType, false, _result);
                        }
                      } catch (error) {
                        handleAgentError(error);
                      }
                    }
                  },
                });
                return;
              }
              case WORKFLOW_INPUT_TYPES.ALERT_CREATION: {
                addNewChat("alert-code-input-text", true, null, {
                  tables,
                  isSubmitted: false,
                  onSubmit: async ({
                    datastoreId,
                    purpose,
                    piiEnabled,
                    config = {},
                  }) => {
                    setShowSuggestions(false);
                    dispatch({ type: ACTIONS.SUBMIT });
                    const requestData = {
                      workflow_type: workflowType,
                      purpose,
                      workflow_inputs: {
                        data_store_id: datastoreId,
                        pii_enabled: piiEnabled,
                        ...config,
                      },
                    };
                    addNewChat("chat-loader", false, null, {
                      label: "Creating a plan",
                    });
                    try {
                      const { tasks: _tasks, pii: _pii } = await postTasks(
                        requestData
                      );
                      dispatch({ type: ACTIONS.DELETE_ITEM });
                      if (_pii) {
                        addNewChat("chat-block", true, null, {
                          data: _pii?.message,
                        });
                      } else {
                        addNewChat("task-list", true, null, {
                          tasks: _tasks,
                          onSubmit: () => {
                            dispatch({ type: ACTIONS.SUBMIT });
                            _createSession(_tasks, requestData);
                          },
                        });
                      }
                    } catch (error) {
                      handleAgentError(error);
                    }
                  },
                });
                return;
              }
              case WORKFLOW_INPUT_TYPES.DBT_QUERY_TEXT: {
                addNewChat("code-input-dropdown", true, null, {
                  isSubmitted: false,
                  onSubmit: async ({
                    purpose,
                    dbt_core_integration_id,
                    dbt_core_integration_environment_id,
                    piiEnabled,
                  }) => {
                    setShowSuggestions(false);
                    dispatch({ type: ACTIONS.SUBMIT });
                    const requestData = {
                      workflow_type: workflowType,
                      purpose,
                      workflow_inputs: {
                        dbt_core_integration_id,
                        dbt_core_integration_environment_id,
                        pii_enabled: piiEnabled,
                      },
                    };
                    addNewChat("chat-loader", false, null, {
                      label: "Creating a plan",
                    });
                    try {
                      const { tasks: _tasks, pii: _pii } = await postTasks(
                        requestData
                      );
                      dispatch({ type: ACTIONS.DELETE_ITEM });
                      if (_pii) {
                        addNewChat("chat-block", true, null, {
                          data: _pii?.message,
                        });
                      } else {
                        addNewChat("task-list", true, null, {
                          tasks: _tasks,
                          onSubmit: () => {
                            dispatch({ type: ACTIONS.SUBMIT });
                            _createSession(_tasks, requestData);
                          },
                        });
                      }
                    } catch (error) {
                      handleAgentError(error);
                    }
                  },
                });
                return;
              }
            }
          }}
        />
        <div />
        {tasks.length > 0 && <div className="fs-3 fw-500">Steps</div>}
        {tasks.map((t) => {
          const _taskStatus = taskStatus[t];
          return (
            <div key={t} className={styles.task_item_left}>
              <div className={styles.task_label}>{t}</div>
              <div className="spacer" />
              <ColorTag
                color={taskStatusColorMap[_taskStatus]}
                label={_taskStatus}
              />
            </div>
          );
        })}
        <History
          sessionId={state.sessionId}
          onItemClick={async ({ session_id, workflow_type }) => {
            if (session_id === state.sessionId) return;
            resetWorkflow();
            dispatch({ type: ACTIONS.SET_SESSION, payload: { session_id } });
            setShowSuggestions(false);
            try {
              const { session_input, chat_history, next_message, tasks } =
                await getChat(session_id);
              setWorkflow(session_input.workflow_type);
              setTasks(session_input.tasks);
              setTaskStatus(tasks);
              const proceedWithChat = async (args) => {
                addNewChat(
                  "chat-loader",
                  false,
                  null,
                  getLoaderArgs(args, next_message)
                );
                const data = await postChat(session_id, args);
                dispatch({ type: ACTIONS.DELETE_ITEM });
                handleChatResponse(session_id, data, workflow_type);
              };
              const timestamp = chat_history.find(
                (c) => c.timestamp
              )?.timestamp;
              switch (
                WORKFLOWS.find((item) => item.value === workflow_type)
                  ?.inputType
              ) {
                case WORKFLOW_INPUT_TYPES.DS_PURPOSE: {
                  addNewChat("table-selection", true, timestamp, {
                    tables,
                    onSubmit: () => {},
                    isSubmitted: true,
                    purpose: session_input.purpose,
                    datastoreId: session_input.workflow_inputs.data_store_id,
                    piiEnabled: session_input.workflow_inputs.pii_enabled,
                  });
                  break;
                }
                case WORKFLOW_INPUT_TYPES.SQL_QUERY: {
                  addNewChat("code-input", true, timestamp, {
                    isSubmitted: true,
                    value: session_input.purpose,
                    piiEnabled: session_input.workflow_inputs.pii_enabled,
                    onSubmit: () => {},
                  });
                  break;
                }
                case WORKFLOW_INPUT_TYPES.SQL_QUERY_TEXT: {
                  addNewChat("code-input-text", true, timestamp, {
                    isSubmitted: true,
                    purpose: session_input.purpose,
                    piiEnabled: session_input.workflow_inputs.pii_enabled,
                    onSubmit: () => {},
                  });
                  break;
                }
                case WORKFLOW_INPUT_TYPES.SQL_QUERY_TAGS: {
                  addNewChat("code-input-tags", true, timestamp, {
                    isSubmitted: true,
                    value: session_input.purpose,
                    piiEnabled: session_input.workflow_inputs.pii_enabled,
                    tags: session_input.workflow_inputs.tags,
                    data_store_id: session_input.workflow_inputs?.data_store_id,
                    query_id: session_input.workflow_inputs?.query_id,
                    start_time: session_input.workflow_inputs?.start_time,
                    onSubmit: () => {},
                  });
                  break;
                }
                case WORKFLOW_INPUT_TYPES.ALERT_CREATION: {
                  addNewChat("alert-code-input-text", true, timestamp, {
                    tables,
                    isSubmitted: true,
                    purpose: session_input.purpose,
                    datastoreId: session_input.workflow_inputs.data_store_id,
                    piiEnabled: session_input.workflow_inputs.pii_enabled,
                    onSubmit: () => {},
                  });
                  break;
                }
                case WORKFLOW_INPUT_TYPES.DBT_QUERY_TEXT: {
                  addNewChat("code-input-dropdown", true, timestamp, {
                    isSubmitted: true,
                    purpose: session_input.purpose,
                    dbtCoreIntegrationId:
                      session_input.workflow_inputs.dbt_core_integration_id,
                    dbtCoreIntegrationEnvironmentId:
                      session_input.workflow_inputs
                        .dbt_core_integration_environment_id,
                    piiEnabled: session_input.workflow_inputs.pii_enabled,
                    onSubmit: () => {},
                  });
                  break;
                }
              }

              addNewChat("task-list", true, timestamp, {
                tasks: session_input.tasks,
                isSubmitted: true,
                onSubmit: () => {},
              });
              const n = chat_history.length;
              chat_history.forEach((item, i) => {
                const isNotLast = i < n - 1;
                if (item.type === "human") return;
                // eslint-disable-next-line no-prototype-builtins
                if (item.hasOwnProperty("approval")) return;

                processChatItem({
                  session_id,
                  workflow,
                  dispatch,
                  addNewChat,
                  proceedWithChat,
                  autoApprove,
                  item,
                  isLive: !isNotLast,
                  defaultValue: isNotLast ? chat_history[i + 1]?.feedback : "",
                  resetWorkflow,
                });
              });
            } catch (e) {
              console.log(e);
            }
          }}
        />
      </div>
      {state.items.length === 0 ? (
        <InitialPage />
      ) : (
        <div className="d-flex flex-column gap-sm flex-grow overflow-x">
          <div className="d-flex align-items-center">
            <div className="fs-4 fw-500">{WORKFLOW_LABELS[workflow]}</div>
            <div className="spacer" />
            {(!isFreeUser || isNonPremiumFeatureAvailableForFreeUser) && (
              <FormGroup switch className="mb-0">
                <Input
                  type="switch"
                  checked={autoApprove}
                  onChange={toggleAutoApprove}
                />
                <Label className="mb-0">Auto approval</Label>
              </FormGroup>
            )}
          </div>
          <ScrollContainer>
            {showSuggestions && (!isFreeUser || showSuggestionsForFreeUser) && (
              <Suggestions
                workflow={workflow}
                handleSuggestionClick={handleSuggestionClick}
                premium={premium}
              />
            )}
            {showCreditLimitReachedForFreeUser && <CreditLimit />}
            {(!isFreeUser || isNonPremiumFeatureAvailableForFreeUser) && (
              <CopilotChat oldArgs={oldArgs} />
            )}
          </ScrollContainer>
        </div>
      )}
    </div>
  );
};

export { Copilot };
