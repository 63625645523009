import React, { useState, useMemo } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Rectangle,
  ResponsiveContainer,
} from "recharts";
import styles from "./styles.module.scss";
import classNames from "classnames";
import dayjs from "dayjs";
import { Dropdown } from "../../Components/Form";
import { ReactComponent as ArrowDown } from "../../assets/icons/arrow_down.svg";
import { RelativeComponentLoader } from "../../Components/Loader";
import { CustomTooltip } from "../../Components/Graph/misc";
import { useAppState } from "@/modules/app/useAppContext";
import { formatNumber } from "@/uiCore";

const TimeFrameSelectorDropdown = ({ options, period, setPeriod }) => {
  const getLabelByPeriod = (period) => {
    return options.find((p) => p.value === period).label;
  };

  return (
    <Dropdown
      id="graph-period-dropdown"
      onChange={setPeriod}
      value={period}
      options={options.map((p) => ({
        label: <div>{p.label}</div>,
        value: p.value,
      }))}
      showDivider
    >
      <div className={styles.graph_dropdown}>
        <div className={styles.graph_label}>{getLabelByPeriod(period)}</div>
        <div className={styles.arrow_down}>
          <ArrowDown />
        </div>
      </div>
    </Dropdown>
  );
};

const AutoManageGraph = ({
  period = 1,
  showPeriodDropdown = true,
  data,
  topInfo,
  setPeriod = null,
  defaultTimeFrame = null,
  width = 750,
}) => {
  const { currency, currencySymbol } = useAppState();
  const [autoTuneFilter, setAutoTuneFilter] = useState("all");

  const timeframes = defaultTimeFrame
    ? defaultTimeFrame
    : [
        { label: "Day", value: 1 },
        { label: "Week", value: 7 },
        { label: "Last 30 Days", value: 30 },
      ];

  const [timeframe, setTimeframe] = useState(period);

  const getParams = () => {
    const params = {};
    if (timeframe) params.period = timeframe;
    return params;
  };

  const dropDownOnChange = (value) => {
    setTimeframe(value);
    if (setPeriod) setPeriod(value);
  };

  const formatDate = (date) => dayjs(date).format("DD-MMM-YY");

  const roundToTwoDecimalPlaces = (number) => {
    return Math.round(number * 100) / 100;
  };

  const stackedData = useMemo(() => {
    if (data.length === 0) {
      console.log("AutoManageGraph: No data available.");
      return [];
    }
    return data.map((entry) => ({
      time: formatDate(entry.date),
      cost: roundToTwoDecimalPlaces(
        entry.actual + entry.spend + entry.potential
      ),
      "Auto Tune Savings": roundToTwoDecimalPlaces(entry.actual),
      "Possible Savings": roundToTwoDecimalPlaces(entry.potential),
      spend: roundToTwoDecimalPlaces(
        Math.max(entry.spend - entry.potential, 0)
      ),
      autoTuneSavingsUI: formatNumber(entry.actual, { currency }),
      possibleSavingsUI: formatNumber(entry.potential, { currency }),
      Spend: formatNumber(entry.spend, { currency }),
    }));
  }, [data]);

  const CustomYAxisTick = ({ x, y, payload }) => (
    <g transform={`translate(${x},${y})`}>
      <text textAnchor="end" dy={5} fill="#66768D">
        {formatNumber(payload.value, { currency })}
      </text>
    </g>
  );

  const CustomXAxisTick = ({ x, y, payload }) => {
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dx={-30}
          dy={4}
          transform="rotate(270)"
          textAnchor="middle"
          fill="#66768D"
        >
          {payload.value}
        </text>
      </g>
    );
  };

  const handleLegendClick = (data) => {
    const datakey = data?.value;

    switch (datakey) {
      case "Spend":
        if (autoTuneFilter === "spend") {
          setAutoTuneFilter("all");
        } else {
          setAutoTuneFilter("spend");
        }
        break;
      case "Possible Savings":
        if (autoTuneFilter === "potential") {
          setAutoTuneFilter("all");
        } else {
          setAutoTuneFilter("potential");
        }
        break;
      case "Auto Tune Savings":
        if (autoTuneFilter === "actual") {
          setAutoTuneFilter("all");
        } else {
          setAutoTuneFilter("actual");
        }
        break;
      default:
        setAutoTuneFilter("all");
    }
  };

  if (data.length === 0) {
    return (
      <RelativeComponentLoader
        componentHeight={40}
        label="Fetching warehouse cost data..."
      />
    );
  }

  return (
    <div className={styles.graph}>
      <div className={styles.info}>
        {Object.keys(topInfo).map((key, i) => {
          const info = topInfo[key];
          if (info.display === "True")
            return (
              <div
                key={i}
                className={classNames(styles.topInfo, {
                  [styles.green]: info.color === "green",
                  [styles.yellow]: info.color === "yellow",
                  [styles.light_green]: info.color === "light_green",
                })}
              >
                <span className={styles.text}>{key}:</span>
                <span className={styles.text}>
                  {formatNumber(info.value, { currency })}
                </span>
              </div>
            );
        })}
        {showPeriodDropdown && (
          <div className={styles.timeframe_inside_graph}>
            <TimeFrameSelectorDropdown
              options={timeframes}
              period={timeframe}
              setPeriod={dropDownOnChange}
            />
          </div>
        )}
      </div>

      <ResponsiveContainer minHeight={400} className="mb-4">
        <BarChart
          data={stackedData}
          margin={{ top: 40, right: 50, bottom: 50 }}
        >
          <CartesianGrid />
          <XAxis
            dataKey="time"
            interval={0}
            label={{ value: "Time", position: "right", fill: "black" }}
            tick={<CustomXAxisTick />}
          />
          <YAxis
            tick={<CustomYAxisTick />}
            label={{
              value: "Cost",
              position: "top",
              fill: "black",
              offset: 20,
            }}
          />
          <Tooltip
            content={
              <CustomTooltip
                nameValue={[
                  { name: "Spend", value: "Spend", color: "#FFCE73" },
                  {
                    name: "Possible Savings",
                    value: "possibleSavingsUI",
                    color: "#DB9717",
                  },
                  {
                    name: "Auto Tune Savings",
                    value: "autoTuneSavingsUI",
                    color: "#01CD8C",
                  },
                ]}
              />
            }
          />
          {autoTuneFilter === "all" || autoTuneFilter === "spend" ? (
            <Bar
              barSize={20}
              dataKey="spend"
              name="Spend"
              fill="#FFCE73"
              stackId="cost"
              shape={(props) => {
                if (
                  props.payload["Auto Tune Savings"] <= 0 &&
                  props.payload["Possible Savings"] <= 0
                ) {
                  props.radius = [5, 5, 0, 0];
                }

                return <Rectangle {...props} />;
              }}
            />
          ) : (
            <Bar
              barSize={20}
              dataKey="s"
              name="Spend"
              fill="#FFCE73"
              stackId="cost"
              shape={(props) => {
                if (
                  props.payload["Auto Tune Savings"] <= 0 &&
                  props.payload["Possible Savings"] <= 0
                ) {
                  props.radius = [5, 5, 0, 0];
                }

                return <Rectangle {...props} />;
              }}
            />
          )}
          {autoTuneFilter === "all" || autoTuneFilter === "potential" ? (
            <Bar
              barSize={20}
              dataKey="Possible Savings"
              fill="#DB9717"
              stackId="cost"
              shape={(props) => {
                if (props.payload["Auto Tune Savings"] <= 0) {
                  props.radius = [5, 5, 0, 0];
                }

                return <Rectangle {...props} />;
              }}
            />
          ) : (
            <Bar
              barSize={20}
              dataKey="p"
              name="Possible Savings"
              fill="#DB9717"
              stackId="cost"
              shape={(props) => {
                if (props.payload["Auto Tune Savings"] <= 0) {
                  props.radius = [5, 5, 0, 0];
                }

                return <Rectangle {...props} />;
              }}
            />
          )}
          {autoTuneFilter === "all" || autoTuneFilter === "actual" ? (
            <Bar
              barSize={20}
              dataKey="Auto Tune Savings"
              fill="#01CD8C"
              stackId="cost"
              radius={[5, 5, 0, 0]}
            />
          ) : (
            <Bar
              barSize={20}
              dataKey="a"
              name="Auto Tune Savings"
              fill="#01CD8C"
              stackId="cost"
              radius={[5, 5, 0, 0]}
            />
          )}
          <Legend
            onClick={handleLegendClick}
            iconType="circle"
            wrapperStyle={{ bottom: -10 }}
            formatter={(value, entry, index) => (
              <span className={styles.legend}>{value}</span>
            )}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export { AutoManageGraph };
